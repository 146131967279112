import { useEffect, useState } from 'react'
import { ChevronLeft, ChevronRight } from 'lucide-react'
import { useSwiper } from 'swiper/react'

export const SliderNavigation = () => {
  const [slideConfig, setSlideConfig] = useState({
    isBeginning: true,
    isEnd: false,
  })
  const swiper = useSwiper()
  useEffect(() => {
    swiper.on('slideChange', (swipe) => {
      setSlideConfig({ isBeginning: swipe.isBeginning, isEnd: swipe.isEnd })
    })
  }, [swiper])

  return (
    <div className="mt-4 flex items-center justify-center space-x-2">
      <button
        onClick={() => {
          swiper.slidePrev()
        }}
        disabled={slideConfig.isBeginning}
        className="rounded-xl bg-accent-600 p-2 text-white hover:bg-accent-800 disabled:bg-gray-300"
      >
        <ChevronLeft className="size-4 " strokeWidth={3} />
      </button>
      <button
        onClick={() => {
          swiper.slideNext()
        }}
        disabled={slideConfig.isEnd}
        className="rounded-xl bg-accent-600 p-2 text-white hover:bg-accent-800 disabled:bg-gray-300"
      >
        <ChevronRight className="size-4 " strokeWidth={3} />
      </button>
    </div>
  )
}
