import React, { FunctionComponent, useEffect, useRef } from 'react'

import { cn } from '@/util/classNames'

type Props = {
  className?: string
  style?: React.CSSProperties
}

export const Loader: FunctionComponent<React.PropsWithChildren<Props>> = ({ className, style }) => {
  return (
    <div
      ref={(el) => el?.style?.setProperty('border-top-color', 'transparent', 'important')}
      style={{ ...style }}
      className={cn(
        `size-5 animate-spin rounded-full border-4 border-solid border-accent-600`,
        className || ''
      )}
    ></div>
  )
}

export const PageLoader = () => {
  return (
    <div className="flex h-screen w-full items-center justify-center overflow-hidden">
      <Loader className="size-36 border-accent-600" />
    </div>
  )
}

export const DataLoader: React.FunctionComponent<
  React.PropsWithChildren<{
    height?: React.CSSProperties['height']
    className?: string
  }>
> = ({ height, className }) => {
  return (
    <div
      className={cn('mx-auto flex h-96 w-full items-center justify-center space-x-2', className)}
      style={{ height: height ?? 384 }}
    >
      <Loader className="size-6 border-4 border-accent-600" /> <p>Loading data...</p>
    </div>
  )
}

export const OscillatingLoading: React.FunctionComponent<
  React.PropsWithChildren<{
    height?: React.CSSProperties['height']
    className?: string
    background?: string
  }>
> = ({ className, height, background }) => {
  const canvas = useRef<HTMLCanvasElement | null>(null)

  const time = useRef<number>(0)

  const animationFrameRequestRef = useRef<number | null>(null)
  const waveCount = 20
  const dotSize = 1
  const stepSize = 5
  const baseCircleRadius = 50

  function drawWavyCircle(
    ctx: CanvasRenderingContext2D,
    x,
    y,
    radius,
    time: number,
    amplitude,
    color
  ) {
    for (let i = 0; i <= 360; i += stepSize) {
      const angle = (i + time) * (Math.PI / 180)
      const waveAngle = (i * waveCount + time) * (Math.PI / 180)
      const amplitudeOscillation = (Math.sin(time) + 1) * amplitude
      const oscillation = Math.sin(waveAngle) * amplitudeOscillation
      const currentRadius = radius + oscillation
      const currentX = x + Math.cos(angle) * currentRadius
      const currentY = y + Math.sin(angle) * currentRadius

      ctx.beginPath()
      ctx.arc(currentX, currentY, dotSize, 0, 2 * Math.PI)
      ctx.fillStyle = color
      ctx.fill()
    }
  }

  function clearBackground(context: CanvasRenderingContext2D): void {
    const { width, height } = context.canvas
    context.rect(0, 0, width, height)
    context.fillStyle = background ?? 'white'
    context.fill()
  }

  function animate() {
    const ctx = canvas.current?.getContext('2d')
    if (!canvas.current) return

    if (ctx) {
      clearBackground(ctx)
      const centerX = canvas.current.width / 2
      const centerY = canvas.current.height / 2
      const c1 = baseCircleRadius + Math.sin(time.current) * 10 // Vary the size
      const c2 = baseCircleRadius + Math.sin(time.current * 0.9) * 10 // Vary the size
      const c3 = baseCircleRadius + Math.sin(time.current * 1.1) * 10 // Vary the size

      drawWavyCircle(ctx, centerX, centerY, c1, time.current, 5, 'rgba(0, 0, 255, 0.5)')
      drawWavyCircle(ctx, centerX, centerY, c2, time.current * 0.8, 3, 'rgba(0, 0, 255, 0.7)')
      drawWavyCircle(ctx, centerX, centerY, c3, time.current * 1.2, 7, 'rgba(0, 0, 255, 0.3)')

      time.current += 0.05
    }
    animationFrameRequestRef.current = requestAnimationFrame(animate)
  }

  useEffect(() => {
    animationFrameRequestRef.current = requestAnimationFrame(animate)
    return () => {
      if (animationFrameRequestRef.current != null) {
        cancelAnimationFrame(animationFrameRequestRef.current)
      }
    }
  }, [])

  return (
    <div
      className={cn('m-auto flex w-full items-center justify-center space-x-2 bg-white')}
      style={{ height: height ?? '100%', background: background }}
    >
      <canvas ref={canvas} width={height ?? '250px'} height={height ?? '250px'}></canvas>
    </div>
  )
}
