import React, {
  Fragment,
  FunctionComponent,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from 'react'
import { Transition } from '@headlessui/react'
import { CheckCircleIcon, XCircleIcon } from '@heroicons/react/outline'
import { XIcon } from '@heroicons/react/solid'
import { createPortal } from 'react-dom'

type ToastContextType = {
  isToasterShown: boolean
  type: 'success' | 'error' | 'warning'
  title: string
  description: string
  showToaster(arg0: ToasterState): void
  closeToaster(): void
}

const ToastContext = React.createContext<ToastContextType>({
  isToasterShown: false,
  type: 'success',
  title: '',
  description: '',
  showToaster: () => {
    /**/
  },
  closeToaster: () => {
    /**/
  },
})

type ToasterState = {
  type: 'success' | 'error' | 'warning'
  title: string
  description: string
}

const ToastProvider = ({ children }: { children: ReactNode }): JSX.Element => {
  const [toasterState, setToasterState] = useState<ToasterState & { isShown: boolean }>({
    isShown: false,
    type: 'success',
    title: '',
    description: '',
  })

  const showToaster = ({
    type,
    title,
    description,
  }: {
    type: 'success' | 'error' | 'warning'
    title: string
    description: string
  }): void =>
    setToasterState((currentToasterState) => ({
      ...currentToasterState,
      type,
      title,
      description,
      isShown: true,
    }))

  const closeToaster = (): void =>
    setToasterState((currentToasterState) => ({
      ...currentToasterState,
      isShown: false,
    }))

  const value = {
    isToasterShown: toasterState.isShown,
    type: toasterState.type,
    title: toasterState.title,
    description: toasterState.description,
    showToaster,
    closeToaster,
  }

  return (
    <ToastContext.Provider value={value}>
      <>
        {children}
        <Toast />
      </>
    </ToastContext.Provider>
  )
}

export const Toast: FunctionComponent<React.PropsWithChildren<unknown>> = () => {
  const { closeToaster, isToasterShown, title, description, type } = useToast()
  const [isMounted, setIsMounted] = useState<boolean>(false)

  useEffect(() => {
    setIsMounted(true)
  }, [])

  const mainContainer = document.getElementById('root')

  return isMounted && mainContainer
    ? createPortal(
        <div
          aria-live="assertive"
          className="pointer-events-none fixed inset-0 flex items-end px-4 py-6 sm:items-start sm:p-6"
          style={{ zIndex: 999 }}
        >
          <div className="flex w-full flex-col items-center space-y-4 sm:items-end">
            {/* Notification panel, dynamically insert this into the live region when it needs to be displayed */}
            <Transition
              show={isToasterShown}
              as={Fragment}
              enter="transform ease-out duration-300 transition"
              enterFrom="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
              enterTo="translate-y-0 opacity-100 sm:translate-x-0"
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="pointer-events-auto w-full max-w-sm overflow-hidden rounded-lg bg-white shadow-lg ring-1 ring-black/5">
                <div className="p-4">
                  <div className="flex items-start">
                    <div className="shrink-0">
                      {type === 'success' ? (
                        <CheckCircleIcon className="size-6 text-green-400" aria-hidden="true" />
                      ) : null}
                      {type === 'error' ? (
                        <XCircleIcon className="size-6 text-red-400" aria-hidden="true" />
                      ) : null}
                    </div>
                    <div className="ml-3 w-0 flex-1 pt-0.5">
                      <p className="text-sm font-medium text-gray-900">{title}</p>
                      <p className="mt-1 text-sm text-gray-500">{description}</p>
                    </div>
                    <div className="ml-4 flex shrink-0">
                      <button
                        className="inline-flex rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                        onClick={() => {
                          closeToaster()
                        }}
                      >
                        <span className="sr-only">Close</span>
                        <XIcon className="size-5" aria-hidden="true" />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </Transition>
          </div>
        </div>,
        mainContainer
      )
    : null
}

const useToast = (): ToastContextType => {
  return useContext(ToastContext)
}

export { useToast, ToastProvider, ToastContext }
