export const months = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
]

export const days = ['SUNDAY', 'MONDAY', 'TUESDAY', 'WEDNESDAY', 'THURSDAY', 'FRIDAY', 'SATURDAY']

export const generateTimeOptions = () => {
  const options: { label: string; value: string }[] = []
  for (let hour = 0; hour < 24; hour++) {
    // Format the hour for the value (24-hour format, two digits)
    const value = hour.toString().padStart(2, '0')
    // // Determine AM/PM suffix
    // const suffix = hour < 12 ? 'AM' : 'PM'
    // // Convert hour to 12-hour format for the label
    // const hour12 = hour % 12 === 0 ? 12 : hour % 12
    // // Format the label
    // const label = `${hour12.toString().padStart(2, '0')}:00 ${suffix}`
    // Push the option
    options.push({ label: value + ':00', value: value + ':00:00' })
  }
  return options
}
