// const groceries = ['🍎 Apples', '🍌 Bananas', '🥦 Broccoli', '🥕 Carrots', '🍫 Chocolate']
import React, { useEffect, useMemo, useState } from 'react'
import { Checkbox, Combobox, Pill, PillsInput, useCombobox } from '@mantine/core'

import { Loader } from '@/components/Loader'
import { cn } from '@/util/classNames'
import { trpc } from '@/util/trpc'

export const ImgBase64 = ({ img, className }: { img?: string; className?: string }) => {
  const { data, isFetching } = trpc.utils.imgToBase64.useQuery(
    {
      url: img ?? '',
    },
    {
      keepPreviousData: true,
      enabled: !!img,
    }
  )

  return isFetching ? (
    <Loader className={cn('size-5 border-2', className)} />
  ) : (
    <img src={data?.image_base64} alt="pp" className={cn('size-5 rounded-full', className)}></img>
  )
}

type Option = {
  value: string
  label: string
  image?: string
}

export function SearchableMultiSelect({
  value: valueProps,
  onChange,
  searchValue: search,
  onSearchChange: setSearch,
  placeholder,
  data,
  label,
  required,
  isLoading,
  error,
}: {
  value: Option[]
  onChange: (value: Option[]) => void
  searchValue: string
  onSearchChange: (value: string) => void
  data: Array<Option>
  placeholder?: string
  required?: boolean
  label: string
  isLoading?: boolean
  error?: string
}) {
  const combobox = useCombobox({
    onDropdownClose: () => combobox.resetSelectedOption(),
    onDropdownOpen: () => combobox.updateSelectedOptionIndex('active'),
  })

  //   const [search, setSearch] = useState('')
  const [value, setValue] = useState<Option[]>([])

  useEffect(() => {
    setValue(valueProps)
  }, [])

  useEffect(() => {
    onChange(value)
  }, [value])

  const handleValueSelect = (val: string) => {
    setValue((current) =>
      value.findIndex((v) => v.value === val) > -1
        ? current.filter((v) => v.value !== val)
        : [...current, { value: val, label: 'val', ...data.find((d) => d.value === val) }]
    )
    setSearch('')
  }

  const handleValueRemove = (val: string) =>
    setValue((current) => current.filter((v) => v.value !== val))

  const values = useMemo(
    () =>
      value.map((item) => (
        <Pill key={item.value} withRemoveButton onRemove={() => handleValueRemove(item.value)}>
          <div className="mr-2 flex w-full items-center space-x-1.5">
            <ImgBase64 img={item.image} className="size-3" />
            <p>{item.label}</p>
          </div>
        </Pill>
      )),
    [value]
  )

  const options = useMemo(
    () =>
      data.map((item) => {
        const isChecked = value.findIndex((v) => v.value === item.value) > -1
        return (
          <Combobox.Option value={item.value} key={item.value} active={isChecked}>
            {/* {value.includes(item.value) ? <CheckIcon size={12} /> : null} */}
            <div className="flex items-center space-x-2">
              <Checkbox size="xs" checked={isChecked} />
              <ImgBase64 img={item.image} />
              <p className="text-sm text-primary-900">{item.label}</p>
            </div>
          </Combobox.Option>
        )
      }),
    [data, value]
  )

  return (
    <div className="space-y-1">
      <label htmlFor="" className="text-sm">
        {label}
        {required && <span className="text-red-500">*</span>}
      </label>
      <Combobox store={combobox} onOptionSubmit={handleValueSelect} withinPortal={false}>
        <Combobox.DropdownTarget>
          <PillsInput
            onClick={() => combobox.openDropdown()}
            rightSection={isLoading ? <Loader className="size-4 border-2" /> : undefined}
            error={error}
          >
            <Pill.Group>
              {values}

              <Combobox.EventsTarget>
                <PillsInput.Field
                  onFocus={() => combobox.openDropdown()}
                  onBlur={() => combobox.closeDropdown()}
                  value={search}
                  placeholder={placeholder}
                  onChange={(event) => {
                    combobox.updateSelectedOptionIndex()
                    setSearch(event.currentTarget.value)
                  }}
                  onKeyDown={(event) => {
                    if (event.key === 'Backspace' && search.length === 0) {
                      event.preventDefault()
                      handleValueRemove(value[value.length - 1].value)
                    }
                  }}
                />
              </Combobox.EventsTarget>
            </Pill.Group>
          </PillsInput>
        </Combobox.DropdownTarget>

        <Combobox.Dropdown hidden={isLoading && options.length === 0}>
          <Combobox.Options mah={200} style={{ overflowY: 'auto' }}>
            {options.length > 0 ? options : <Combobox.Empty>Nothing found...</Combobox.Empty>}
          </Combobox.Options>
        </Combobox.Dropdown>
      </Combobox>
    </div>
  )
}
