import React, { useMemo, useState } from 'react'
import { ExclamationIcon, TrashIcon, XIcon } from '@heroicons/react/outline'
import { Accordion, Button, Checkbox, Modal, TextInput } from '@mantine/core'
import * as Sentry from '@sentry/react'
import {
  ColumnDef,
  getCoreRowModel,
  getSortedRowModel,
  PaginationState,
  useReactTable,
  VisibilityState,
} from '@tanstack/react-table'
import { capitalCase } from 'change-case'
import dayjs from 'dayjs'
import { Circle } from 'lucide-react'
import { usePostHog } from 'posthog-js/react'
import { SearchMd } from 'untitled-icons'

import { EmptyStatev2 } from '@/components/EmptyState'
import { ErrorFallback } from '@/components/ErrorFallback'
import { Loader } from '@/components/Loader'
import { SelectMenu } from '@/components/SelectMenu'
import { Table } from '@/components/Table'
import useDebounce from '@/hooks/useDebounce'
import AlignLeftIcon from '@/icons/AlignLeft'
import CalendarIcon from '@/icons/Calendar'
import ListIcon from '@/icons/List'
import { cn } from '@/util/classNames'
import { notifyError } from '@/util/error'
import { multiSelectFilter } from '@/util/multiselectFilter'
import { notifySuccess } from '@/util/success'
import { trpc } from '@/util/trpc'
import { AutomationsModal } from './Automations'

type Sort = 'asc' | 'desc'

export const REPORT_MAP = {
  CI__V1: 'Google Insights Report',
  CI__V2: 'Google Insights Report',
  AZ__V1: 'Amazon Bestselling Products',
  AZ__V2: 'Amazon Bestselling Products',
  IG__V1: 'Instagram Summary Report',
  NP__V1: 'New Products',
  NP__V2: 'New Products',
  PS__V1: 'Product Summary',
  PP__V1: 'Product Performance',
  PP__V2: 'Product Performance',
  QVC__V1: 'QVC Report',
  HSE__V1: 'HSE Report',
  BRAND__V1: 'Brand Report',
}

export const SNIPPETS = {
  CI__V1__LIGHT: 'Google Insights Report',
  NP__V1__LIGHT: 'New Products',
}

const REPORT_CLASS = {
  REPORT: 'AI Reports',
  DATA_SNIPPET: 'AI Data Snippets',
}

export const RecentReports = () => {
  const posthog = usePostHog()
  const [search, setSearch] = useState('')
  const debouncedQuery = useDebounce(search, 100)
  const [sort, setSort] = useState<Sort>('desc')
  const [{ pageIndex, pageSize }, setPagination] = React.useState<PaginationState>({
    pageIndex: 0,
    pageSize: 8,
  })
  const [reportTypes, setReportTypes] = useState<string[]>([])
  const { data, isLoading } = trpc.report.getReports.useQuery(
    { order: sort, search: debouncedQuery, page: pageIndex, limit: pageSize, reportTypes },
    {
      refetchInterval: 5 * 1000, // refetch every 5 seconds
    }
  )
  const { data: filterInfo } = trpc.report.filterInfo.useQuery()

  const [columnVisibility, setColumnVisibility] = useState<VisibilityState>({
    report_slides_thumbnails: false,
    report_created_at: false,
    report_url: false,
    report_class: false,
    created_by_user: false,
  })

  const columns = useMemo<ColumnDef<any, any>[]>(
    () => [
      { accessorKey: 'report_slides_thumbnails' },
      { accessorKey: 'report_created_at' },
      { accessorKey: 'report_url' },
      { accessorKey: 'report_class' },
      { accessorKey: 'created_by_user' },
      {
        header: () => (
          <div className="flex items-center space-x-2">
            <AlignLeftIcon className="size-4" />
            <p>Title</p>
          </div>
        ),
        accessorKey: 'report_name_custom',
        meta: {
          className: 'text-left',
          headerClassName: 'bg-primary-50',
        },
        cell: ({ getValue, row }) => {
          const reportStatus = row.getValue('report_status')
          const createdByUser = row.getValue('created_by_user')

          const reportClass = row.getValue<string>('report_class')
          const reportType = row.getValue<string>('report_type')
          const isPending = !(reportStatus === 'DONE' || reportStatus === 'FAILED')
          return (
            <div
              style={{ textTransform: 'capitalize' }}
              className="truncate text-left text-sm font-normal text-primary-900"
            >
              <div className="flex items-center space-x-5">
                <div className="relative shrink-0">
                  <img
                    src={
                      isPending
                        ? '/custom-reports/webp/pending.webp'
                        : row.getValue<string[]>('report_slides_thumbnails')[0] ??
                          '/report-covers/webp/Skincare New Keywords on Google-cover.webp'
                    }
                    alt=""
                    className="h-12 rounded-md object-contain"
                  />
                  {/* {isPending && (
                    <div className="absolute right-4 top-3 ">
                      <div className="loader-dots"></div>
                    </div>
                  )} */}
                </div>
                <div className="space-y-1 truncate">
                  <a
                    href={row.getValue('report_url')}
                    className={cn(
                      'truncate font-medium text-primary-500',
                      reportStatus === 'DONE' && 'text-primary-900 underline',
                      isPending && 'pointer-events-none '
                    )}
                    target="_blank"
                    rel="noreferrer"
                    onClick={() => {
                      posthog?.capture('AI Report Opened', {
                        reportName: getValue(),
                        createdByUser,
                        reportType,
                      })
                    }}
                  >
                    {getValue()}
                  </a>
                  {reportClass && (
                    <p
                      className="flex h-[16px] w-fit flex-col justify-center rounded px-2 text-[8px] font-medium"
                      style={{
                        backgroundColor: { REPORT: '#C0E8FF', DATA_SNIPPET: '#C0FFEC' }[
                          reportClass
                        ],
                      }}
                    >
                      {REPORT_CLASS[reportClass]}
                    </p>
                  )}
                </div>
              </div>
            </div>
          )
        },
      },
      {
        header: () => (
          <div className="flex items-center space-x-2">
            <ListIcon className="size-4" />
            <p>Type</p>
          </div>
        ),
        accessorKey: 'report_type',
        meta: {
          headerClassName: 'bg-primary-50',
          className: 'text-left',
        },
        cell: ({ getValue, row }) => {
          return <p className="text-primary-700">{REPORT_MAP[getValue()]}</p>
        },
      },
      {
        header: () => (
          <div className="flex items-center space-x-2">
            <CalendarIcon className="size-4" />
            <p>Date</p>
          </div>
        ),
        accessorKey: 'report_requested_at',
        size: 120,
        meta: {
          headerClassName: 'bg-primary-50',
          className: 'text-left',
        },
        cell: ({ getValue, row }) => {
          return (
            <p className="text-primary-700">
              {dayjs(row.getValue('report_created_at') ?? getValue()).format('DD/MM/YYYY')}
            </p>
          )
        },
      },
      {
        header: () => (
          <div className="flex items-center justify-center space-x-2">
            <Circle className="size-4" />
            <p>Status</p>
          </div>
        ),
        size: 130,
        accessorKey: 'report_status',
        meta: {
          headerClassName: 'bg-primary-50',
          //   className: 'text-left',
        },
        cell: ({ getValue }) => {
          const status = getValue()
          const isPending = !(status === 'DONE' || status === 'FAILED')
          return (
            <div className="flex justify-center">
              <p
                className={cn(
                  'w-fit whitespace-nowrap rounded-md px-2 py-1 text-xs font-medium capitalize',
                  {
                    DONE: 'bg-green-200',
                    FAILED: 'bg-red-200',
                  }[status],
                  isPending && 'bg-[#FBE7E9]'
                )}
              >
                {isPending ? 'In progress' : capitalCase(status)}
              </p>
            </div>
          )
        },
      },
      {
        accessorKey: 'report_id',
        header: () => null,
        meta: {
          headerClassName: 'bg-primary-50',
        },
        size: 50,
        cell: ({ getValue, row }) => {
          return (
            <div>
              <ConfirmationModal id={getValue()} />
            </div>
          )
        },
      },
    ],
    []
  )
  const pagination = React.useMemo(
    () => ({
      pageIndex,
      pageSize,
    }),
    [pageIndex, pageSize]
  )

  const table = useReactTable({
    data: data?.data ?? [],
    columns,
    state: {
      pagination,
      columnVisibility,
    },
    defaultColumn: {
      size: 400, //starting column size
      minSize: 10, //enforced during column resizing
      maxSize: 500, //enforced during column resizing
    },
    pageCount: data?.pagination.pages ?? -1,
    manualPagination: true,
    filterFns: { multiSelect: multiSelectFilter },
    onColumnVisibilityChange: setColumnVisibility,
    onPaginationChange: setPagination,
    enableSortingRemoval: false,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
  })
  const total = data?.pagination.total

  return (
    <div className="max-w-layout my-16 space-y-2">
      <h2 className="text-lg font-semibold">Requested Reports</h2>
      {total === 0 && !isLoading && reportTypes.length === 0 ? (
        <EmptyStatev2
          message={<p>Looks like it&apos;s quiet here! No reports to show at the moment.</p>}
        />
      ) : (
        <div className="grid grid-cols-5 gap-14">
          <div>
            <Accordion
              defaultValue={['AI Reports', 'AI Data Snippets']}
              px={0}
              styles={{
                label: { fontSize: '12px', fontWeight: 500, padding: 0 },
                control: {
                  padding: '12px 0',
                },
                content: {
                  padding: '0 0 12px',
                },
              }}
              multiple
              classNames={{ control: 'hover:!bg-transparent' }}
            >
              <Accordion.Item value="AI Reports">
                <Accordion.Control className="capitalize">AI Reports</Accordion.Control>
                <Accordion.Panel>
                  <Checkbox.Group value={reportTypes} onChange={setReportTypes}>
                    <div className="space-y-3">
                      {Object.keys(REPORT_MAP).map((reportType) => {
                        const count =
                          filterInfo?.find((i) => i.report_type === reportType)?.count ?? 0
                        return (
                          <Checkbox
                            key={reportType}
                            value={reportType}
                            disabled={count === 0}
                            size="xs"
                            label={
                              <p className="text-xs">
                                {REPORT_MAP[reportType]}{' '}
                                <span className="text-primary-500">({count})</span>
                              </p>
                            }
                          />
                        )
                      })}
                    </div>
                  </Checkbox.Group>
                </Accordion.Panel>
              </Accordion.Item>
              <Accordion.Item value="AI Data Snippets">
                <Accordion.Control className="capitalize">AI Data Snippets</Accordion.Control>
                <Accordion.Panel>
                  <Checkbox.Group value={reportTypes} onChange={setReportTypes}>
                    <div className="space-y-3">
                      {Object.keys(SNIPPETS).map((reportType) => {
                        const count =
                          filterInfo?.find((i) => i.report_type === reportType)?.count ?? 0
                        return (
                          <Checkbox
                            key={reportType}
                            value={reportType}
                            disabled={count === 0}
                            size="xs"
                            label={
                              <p className="text-xs">
                                {SNIPPETS[reportType]}{' '}
                                <span className="text-primary-500">({count})</span>
                              </p>
                            }
                          />
                        )
                      })}
                    </div>
                  </Checkbox.Group>
                </Accordion.Panel>
              </Accordion.Item>
            </Accordion>
          </div>
          <div className="col-span-4 space-y-4">
            {total === 0 && !isLoading ? (
              <EmptyStatev2
                message={<p>Looks like it&apos;s quiet here! No reports to show at the moment.</p>}
              />
            ) : (
              <>
                <div className="flex justify-between space-x-3">
                  <TextInput
                    leftSection={<SearchMd className="ml-1 size-4" />}
                    value={search}
                    onChange={(e) => setSearch(e.target.value)}
                    placeholder="Search for a category or a report..."
                    width={'100%'}
                    className="w-full shrink"
                  />
                  <SelectMenu
                    options={[
                      { label: 'Newest first', value: 'desc' },
                      { label: 'Oldest first', value: 'asc' },
                    ]}
                    value={sort}
                    onChange={(value) => setSort(value as Sort)}
                    className="h-[36px]"
                  />
                  <AutomationsModal />
                </div>
                <div className="scroller-sm">
                  <Table
                    table={table}
                    height={73 * Math.min(total ?? 8, 8) + 44}
                    loading={isLoading}
                    noDataMessage={
                      <p>Looks like it&apos;s quiet here! No reports to show at the moment.</p>
                    }
                  />

                  {(total ?? 0) > 0 && (data?.pagination.pages ?? 0) > 1 && (
                    <div className="flex items-center gap-2 p-4">
                      <Button
                        size="xs"
                        onClick={() => table.previousPage()}
                        disabled={!table.getCanPreviousPage()}
                      >
                        Prev
                      </Button>
                      <Button
                        size="xs"
                        onClick={() => table.nextPage()}
                        disabled={!table.getCanNextPage()}
                      >
                        Next
                      </Button>
                    </div>
                  )}
                </div>
              </>
            )}
          </div>
        </div>
      )}
    </div>
  )
}
export const ConfirmationModal = ({ id }: { id: string }) => {
  const [opened, setOpened] = useState(false)

  const utils = trpc.useContext()
  const { mutate, isLoading: isDeleting } = trpc.report.deleteReport.useMutation({
    onSuccess: (data) => {
      notifySuccess('Report Deleted.', 'Report has been successfully deleted.')
      utils.report.getReports.invalidate()
    },
    onError: notifyError,
  })
  return (
    <>
      <Modal
        opened={opened}
        onClose={() => {
          setOpened(false)
        }}
        size={444}
        centered
        withCloseButton={false}
        className="relative"
        p={0}
      >
        <button
          className="absolute right-8 top-8"
          onClick={(e) => {
            e.stopPropagation()
            e.preventDefault()
            setOpened(false)
          }}
        >
          <XIcon className="size-5 text-primary-500" />
        </button>
        <div className="mx-auto flex min-h-[350px] max-w-[80%] flex-col justify-center space-y-4 text-center">
          <div className="mx-auto flex items-center justify-center space-x-1 text-center">
            <ExclamationIcon className=" size-12 rounded-full border-[6px] border-red-50 bg-red-100 p-1 text-red-700" />
          </div>
          <h1 className="text-base font-semibold">
            Are you sure you <br /> want to delete this report?
          </h1>
          <p className="text-sm text-primary-500">This action can&apos;t be undone!</p>
          <div className="grid grid-cols-2 gap-2">
            <Button
              variant="default"
              onClick={(e) => {
                setOpened(false)
              }}
              fullWidth
            >
              Cancel
            </Button>
            <Button
              onClick={(e) => {
                mutate({ id })
                setOpened(false)
              }}
              fullWidth
            >
              Delete Report
            </Button>
          </div>
        </div>
      </Modal>
      <button
        onClick={(e) => {
          e.stopPropagation()
          e.preventDefault()
          setOpened(true)
        }}
        disabled={isDeleting}
      >
        {isDeleting ? (
          <Loader className="size-4 border-2 " />
        ) : (
          <TrashIcon className="size-5 text-gray-900" />
        )}
      </button>
    </>
  )
}

export default Sentry.withErrorBoundary(RecentReports, {
  fallback: ({ error, componentStack }) => {
    console.error({ error, componentStack })
    return <ErrorFallback title="Recent Reports" />
  },
})
