import { FunctionComponent } from 'react'
import { LogoutIcon, UserIcon } from '@heroicons/react/solid'
import { shutdown } from '@intercom/messenger-js-sdk'
import { Menu } from '@mantine/core'
import { useVeltClient } from '@veltdev/react'
import { usePostHog } from 'posthog-js/react'
import { Link, useNavigate } from 'react-router-dom'

import { Loader } from '@/components/Loader'
import { useAuth } from '@/hooks/useAuth'
import { trpc } from '@/util/trpc'

declare const window: any

export const ProfileMenu: FunctionComponent<React.PropsWithChildren<unknown>> = () => {
  const navigate = useNavigate()
  const posthog = usePostHog()
  const { client } = useVeltClient()

  const { mutate, isLoading } = trpc.user.logout.useMutation({
    onSuccess: async () => {
      try {
        navigate('/login')
        const utils = trpc.useContext()
        await utils.invalidate()
        posthog?.reset()
        shutdown()
      } catch (error) {
        console.error('Logout error:', error)
        window.location.href = '/login'
      }
    },
  })
  const { user } = useAuth()
  const image = user?.image || ''
  return (
    <div className="inline-block text-left">
      <Menu shadow="md" width={200} position="bottom-end" offset={14}>
        <Menu.Target>
          {image ? (
            <img
              className="mr-2 size-10 cursor-pointer rounded-full object-cover"
              src={image}
              alt=""
            />
          ) : (
            <div className="cursor-pointer rounded-full bg-gray-100 p-2.5">
              <UserIcon className="size-5 text-primary-300" />
            </div>
          )}
        </Menu.Target>

        <Menu.Dropdown>
          <Menu.Item
            leftSection={<UserIcon className="size-4 text-primary-500" />}
            component={Link}
            className="text-sm font-medium"
            to="/userProfile"
          >
            Profile settings
          </Menu.Item>
          <Menu.Item
            leftSection={
              isLoading ? (
                <Loader className="size-4 border-2" />
              ) : (
                <LogoutIcon className="size-4 text-primary-500" />
              )
            }
            onClick={() => {
              mutate()
              client?.signOutUser()
            }}
            className="text-sm font-medium"
          >
            Logout
          </Menu.Item>
        </Menu.Dropdown>
      </Menu>
    </div>
  )
}
