import React, { useContext, useRef } from 'react'
import { LoadingOverlay } from '@mantine/core'
import { flexRender, type Table as TableType } from '@tanstack/react-table'
import { range } from 'lodash'
import { useVirtual } from 'react-virtual'

import { ExportMenuContext } from '@/components/ExportMenu'
import { cn } from '@/util/classNames'
import { ErrorCard } from '../ErrorCard'
import s from './Table.module.css'

type Props = {
  table: TableType<any>
  height?: React.CSSProperties['height']
  noDataMessage?: React.ReactNode
  hasError?: boolean
  minRows?: number
  visibleRows?: number
  loading?: boolean
  className?: string
  emptyStateComponent?: React.ReactNode
  style?: React.CSSProperties
}

export const Table: React.FunctionComponent<React.PropsWithChildren<Props>> = ({
  table,
  height = 'inherit',
  noDataMessage,
  minRows,
  visibleRows,
  hasError,
  loading = false,
  className,
  emptyStateComponent,
  style,
}) => {
  const tableContainerRef = useRef<HTMLDivElement>(null)
  const exportMenu = useContext(ExportMenuContext)

  const { rows: allRows } = table.getRowModel()
  const rows = allRows.slice(0, exportMenu?.exporting ? 50 : allRows.length)
  const rowVirtualizer = useVirtual({
    parentRef: tableContainerRef,
    size: rows.length,
    overscan: 8,
  })

  const { virtualItems: virtualRows, totalSize } = rowVirtualizer

  const paddingTop = virtualRows.length > 0 ? virtualRows?.[0]?.start || 0 : 0
  const paddingBottom =
    virtualRows.length > 0 ? totalSize - (virtualRows?.[virtualRows.length - 1]?.end || 0) : 0

  return (
    <div
      ref={tableContainerRef}
      className={cn('relative overflow-y-auto', className)}
      style={{ height: exportMenu?.exporting ? 'auto' : height, ...style }}
    >
      <LoadingOverlay visible={loading} overlayProps={{ blur: 2, zIndex: 1 }} />

      <table className={s.table}>
        <thead className={cn(`bg-white`)}>
          {table.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => {
                return (
                  <th
                    className={cn(
                      header.column.getCanSort() ? 'cursor-pointer select-none ' : '',

                      header.column.columnDef?.meta?.headerClassName,
                      header.column.columnDef?.meta?.className,
                      '!border-x-0 px-4 py-3 font-medium'
                    )}
                    key={header.id}
                    colSpan={header.colSpan}
                    style={{ width: header.getSize() }}
                    onClick={header.column.getToggleSortingHandler()}
                  >
                    {header.isPlaceholder
                      ? null
                      : flexRender(header.column.columnDef.header, header.getContext())}
                  </th>
                )
              })}
            </tr>
          ))}
        </thead>
        <tbody>
          {paddingTop > 0 && (
            <tr>
              <td className=" py-3" style={{ height: `${paddingTop - 8}px` }} />
            </tr>
          )}
          {virtualRows.map((virtualRow) => {
            const row = rows[virtualRow.index]
            return (
              <tr
                key={row.id}
                className={cn(
                  `py-3 text-center text-sm font-normal text-primary-900`,
                  visibleRows && visibleRows - 1 < virtualRow.index && 'pointer-events-none blur-sm'
                )}
              >
                {row.getVisibleCells().map((cell) => (
                  <td
                    key={cell.id}
                    className={cn(
                      `!border-x-0 px-4 py-3`,
                      cell.column.columnDef?.meta?.cellClassName,
                      cell.column.columnDef?.meta?.className
                    )}
                  >
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </td>
                ))}
              </tr>
            )
          })}

          {minRows
            ? virtualRows.length > 0 &&
              virtualRows.length < minRows &&
              range(0, minRows - virtualRows.length).map((row, id) => (
                <tr key={id} className={cn(`py-3 text-center`)}>
                  {table.getHeaderGroups().map((headerGroup) =>
                    headerGroup.headers.map((header) => (
                      <td
                        key={header.id}
                        className={cn(
                          '!border-x-0 px-4 py-3',
                          header.column.columnDef?.meta?.cellClassName,
                          header.column.columnDef?.meta?.className
                        )}
                      >
                        &#x2015;
                      </td>
                    ))
                  )}
                </tr>
              ))
            : null}
          {paddingBottom > 0 && (
            <tr>
              <td style={{ height: `${paddingBottom}px` }} />
            </tr>
          )}
        </tbody>
      </table>

      {hasError ? (
        <ErrorCard height={'80%'} />
      ) : (
        virtualRows.length === 0 &&
        (emptyStateComponent ? (
          emptyStateComponent
        ) : (
          <div className="flex h-4/5 w-full flex-col items-center justify-center space-y-2 text-center text-sm">
            {/* <img src="/icons/no-data.svg" alt="warning" className="h-20 w-20" /> */}
            {noDataMessage ?? (
              <>
                <p className="text-base font-medium text-primary-900">Sorry!</p>
                <p className="text-sm">
                  eyva here! at the moment I could not<br></br>detect any data for this chart.
                </p>
              </>
            )}
          </div>
        ))
      )}
    </div>
  )
}
