import React, { useCallback, useState } from 'react'
import { Popover } from '@mantine/core'

import { cn } from '@/util/classNames'

export const HoverLongText: React.FunctionComponent<
  React.PropsWithChildren<{ text?: string; className?: string }>
> = ({ text, className, children }) => {
  const [showTooltip, setShowTooltip] = useState(false)

  // MOUSE ENTER HANDLER
  const mouseEnterHandler = useCallback(
    (e: any) => {
      if (e.target.offsetWidth !== e.target.scrollWidth && !showTooltip) {
        setShowTooltip(true)
      } else if (e.target.offsetWidth === e.target.scrollWidth && showTooltip) {
        setShowTooltip(false)
      }
    },
    [showTooltip, setShowTooltip]
  )

  return (
    <Popover
      width={280}
      shadow="md"
      position="bottom-start"
      opened={showTooltip}
      withinPortal={true}
    >
      <Popover.Target>
        <div
          className={cn('truncate', className)}
          onMouseEnter={mouseEnterHandler}
          onMouseLeave={() => setShowTooltip(false)}
        >
          {children}
        </div>
      </Popover.Target>
      <Popover.Dropdown>
        <p className="whitespace-pre-wrap text-left text-sm">{children}</p>
      </Popover.Dropdown>
    </Popover>
  )
}
