import { createContext, ReactNode } from 'react'

interface ProductIdsContext {
  productIds?: number[]
}

export const ProductIdsContext = createContext<ProductIdsContext>({} as ProductIdsContext)

export const ProductIdsProvider = ({
  productIds,
  children,
}: {
  productIds?: number[]
  children: ReactNode
}) => {
  return <ProductIdsContext.Provider value={{ productIds }}>{children}</ProductIdsContext.Provider>
}
