import React, { ReactNode, useCallback, useContext, useMemo, useState } from 'react'
import {
  AutocompleteProps,
  Button,
  Checkbox,
  Combobox,
  Stepper,
  Textarea,
  TextInput,
  useCombobox,
} from '@mantine/core'
import { createFormContext } from '@mantine/form'
import * as Sentry from '@sentry/react'
import { atom, useAtom, useSetAtom } from 'jotai'
import { createIsolation } from 'jotai-scope'
import numberToWords from 'number-to-words'
import { usePostHog } from 'posthog-js/react'
import ReactMarkdown from 'react-markdown'
import { useParams } from 'react-router-dom'
import remarkGfm from 'remark-gfm'
import { Pagination as SwiperPagination } from 'swiper/modules'
import { Swiper, SwiperSlide } from 'swiper/react'

import { ErrorFallback } from '@/components/ErrorFallback'
import { Loader } from '@/components/Loader'
import { MultilevelSelect } from '@/components/MultiLevelSelect'
import { MultiSelectFilter } from '@/components/MultiSelectFilter'
import { SelectMenu } from '@/components/SelectMenu'
import useDebounce from '@/hooks/useDebounce'
import { notifyError } from '@/util/error'
import { days, generateTimeOptions } from '@/util/months'
import { RouterOutput, trpc } from '@/util/trpc'
import { ProductIdsContext } from '../../product-comparison/_components/ProductIdsContext'
import { BrandSelectMenu } from './BrandSelectMenu'
import { SearchableMultiSelect } from './SearchableMultiselect'

const [FormProvider, useFormContext, useForm] = createFormContext<any>()
const searchValueAtom = atom('')
const { Provider, useAtom: useSearchValueAtom } = createIsolation()
const labelValueAtom = atom<Record<string, any>>({})

type Field = {
  field_name: string
  required: boolean
  display_name: string
  placeholder?: string
  type: string
  ui_element: string
  default?: string
  options?: {
    type: string
    api?: {
      url: string
      method: string
      body?: any
    }
    values?: Array<{
      value: string
      label: string
    }>
  }
  additional_info?: string
}

export const StepperForm = Sentry.withErrorBoundary(
  ({
    data,
    reportType,
    close,
  }: {
    data: NonNullable<RouterOutput['report']['reportInfo']>
    reportType: string
    close: () => void
  }) => {
    const productIds = useContext(ProductIdsContext).productIds
    const posthog = usePostHog()
    const { id: compareId } = useParams()
    const [activeStep, setActiveStep] = useState(0)
    const utils = trpc.useContext()
    const [labelValue, setLabelValue] = useAtom(labelValueAtom)

    const noOfSteps = useMemo(
      () => (data?.steps.length ?? 0) + (reportType === 'IG__V1' ? 1 : 0),
      [data?.steps.length, reportType]
    )

    const lastStepWords = useMemo(() => numberToWords.toWords(noOfSteps), [noOfSteps])

    const { mutate, isLoading: isSubmitting } = trpc.report.createReport.useMutation({
      onSuccess: () => {
        setActiveStep(noOfSteps - 1)
        utils.report.getReports.invalidate()
      },
      onError: notifyError,
    })

    const fields = useMemo(() => {
      const fields = data?.steps?.flatMap((step) => {
        return step.fields
      })
      return fields?.reduce((acc, field) => {
        if (!field) return acc
        if ('fields' in field) {
          acc.push(...field.fields)
          return acc
        }
        acc.push(field)
        return acc
      }, [] as Field[])
    }, [data?.steps])

    const form = useForm({
      validateInputOnBlur: true,
      initialValues: {
        ...fields.reduce((acc, field) => {
          if (field?.default) {
            acc[field.field_name] = field.default
          }

          return acc
        }, {} as Record<string, string>),
        ...(reportType === 'IG__V1' && {
          execute_every_x_days: '7',
          execute_now: true,
          execute_day_of_week: 'FRIDAY',
          execute_time_of_day: '08:00:00',
        }),
      },
      validate(values) {
        const errorSteps = data.steps.map((step) => {
          const fields = step.fields?.reduce((acc, field) => {
            if (!field) return acc
            if ('fields' in field) {
              acc.push(...field.fields)
              return acc
            }
            acc.push(field)
            return acc
          }, [] as Field[])
          if (fields?.some((field) => field.required)) {
            return fields.reduce((acc, field) => {
              if (field?.required && !values[field.field_name]) {
                acc[field.field_name] = 'This field is required.'
              }
              if (
                field?.required &&
                Array.isArray(values[field.field_name]) &&
                values[field.field_name].length === 0
              ) {
                acc[field.field_name] = 'This field is required.'
              }
              return acc
            }, {} as any)
          }
          return {}
        })
        return errorSteps[activeStep]
      },
    })

    const nextStep = useCallback(
      () =>
        setActiveStep((current) => {
          if (form.validate().hasErrors) {
            return current
          }
          return current < noOfSteps ? current + 1 : current
        }),
      [form, noOfSteps]
    )

    const prevStep = useCallback(
      () => setActiveStep((current) => (current > 0 ? current - 1 : current)),
      []
    )

    return (
      <Provider>
        <FormProvider form={form}>
          <form
            className="space-y-5"
            onSubmit={form.onSubmit((formValues) => {
              if (reportType === 'PS__V1') {
                formValues['p_c_ids'] = productIds
              }
              const {
                execute_every_x_days,
                execute_day_of_week,
                execute_time_of_day,
                execute_now,
                report_geo,
                ...values
              } = formValues

              // const [hour, minute] = execute_time_of_day?.split(':') || []

              // const time = dayjs()
              //   .hour(parseInt(hour))
              //   .minute(parseInt(minute))
              //   .second(0)
              //   .millisecond(0)
              //   // .utc()
              //   .format('HH:mm:ss')

              const val = {
                fields: [
                  ...Object.entries(values).map(([key, value]) => {
                    if (key === 'ig_handle')
                      return {
                        field_name: key,
                        value: (value as any[]).map((val) => ({
                          profile_id: val.value,
                          user_name: val.label,
                        })),
                      }
                    return {
                      field_name: key,
                      value: value as string,
                    }
                  }),
                  ...(execute_every_x_days && execute_every_x_days !== 'once'
                    ? [
                        {
                          field_name: 'automation',
                          value: {
                            execute_every_x_days: parseInt(execute_every_x_days),
                            execute_day_of_week,
                            execute_time_of_day,
                            execute_now,
                          },
                        },
                      ]
                    : []),
                  {
                    field_name: 'report_geo',
                    value: report_geo ?? 'de',
                  },
                ],
                report_type: reportType,
              }

              const phVal = {
                fields: Object.entries(formValues).map(([key, value]) => ({
                  field_name: key,
                  value: labelValue[key] ?? value,
                })),
                report_type: reportType,
              }
              console.log({ val, phVal })
              posthog?.capture('ICE Report Generated', phVal)
              mutate({ ...val, compareId })
            })}
          >
            <Stepper active={activeStep} onStepClick={setActiveStep}>
              <Stepper.Step label="Step one" description="Introduction" allowStepSelect={false}>
                <div className=" mx-auto min-h-[450px] max-w-4xl">
                  <p className="py-6 text-center text-lg">
                    <span className="font-semibold">Step one</span>: Introduction
                  </p>
                  <div className="grid grid-cols-3 gap-6">
                    <div>
                      <Swiper
                        slidesPerView={1}
                        // spaceBetween={15}
                        // slidesPerGroup={5}
                        pagination={{
                          clickable: true,
                        }}
                        modules={[SwiperPagination]}
                      >
                        {data?.steps[0].images?.map((preview, index) => (
                          <SwiperSlide key={index}>
                            <img
                              key={index}
                              src={preview}
                              alt="report-thumbnail"
                              className={'w-full rounded-md object-contain'}
                            />
                          </SwiperSlide>
                        ))}
                      </Swiper>
                    </div>
                    <div className="col-span-2">
                      <div className="markdown !max-w-none overflow-y-auto">
                        <ReactMarkdown remarkPlugins={[remarkGfm]}>
                          {data?.steps[0]?.description ?? ''}
                        </ReactMarkdown>
                      </div>
                    </div>
                  </div>
                </div>
              </Stepper.Step>

              {data?.steps.slice(1, -1).map((step, index) => {
                const stepWord = numberToWords.toWords(index + 2)
                return (
                  <Stepper.Step
                    key={index}
                    label={`Step ${stepWord}`}
                    description={step.name}
                    allowStepSelect={false}
                  >
                    <div className="min-h-[450px]">
                      <p className="py-6 text-center text-lg">
                        <span className="font-semibold">Step {stepWord}</span>: {step.name}
                      </p>
                      <div className="mx-auto max-w-lg space-y-5">
                        {step?.fields?.map((field, id) =>
                          'fields' in field ? (
                            <div key={id} className="grid grid-cols-2 gap-4">
                              {field.fields.map((field, id) => (
                                <Fields key={id} field={field} reportType={reportType} />
                              ))}
                            </div>
                          ) : (
                            <Fields key={id} field={field} reportType={reportType} />
                          )
                        )}
                        {step?.description}
                      </div>
                    </div>
                  </Stepper.Step>
                )
              })}
              {reportType === 'IG__V1' && (
                <Stepper.Step
                  label={`Step ${numberToWords.toWords(noOfSteps - 1)}`}
                  description="Automate"
                  allowStepSelect={false}
                >
                  <div className="min-h-[450px]">
                    <div className=" mx-auto max-w-lg">
                      <p className="py-6 text-center text-lg">
                        <span className="font-semibold">
                          Step {numberToWords.toWords(noOfSteps - 1)}
                        </span>
                        : Report AI Automation Preferences
                      </p>
                      <div className="mx-auto mt-16 space-y-4">
                        <div className="text-center">
                          How often you want to receive this report?
                        </div>
                        <div className="flex items-center justify-center space-x-5 text-sm">
                          <SelectMenu
                            options={[
                              { label: 'Once', value: 'once' },
                              { label: 'Every 7 days', value: '7' },
                              { label: 'Every 14 days', value: '14' },
                              { label: 'Every 30 days', value: '30' },
                            ]}
                            {...form.getInputProps('execute_every_x_days')}
                            className="h-[36px]"
                            width={150}
                            popoverBaseProps={{ width: 170 }}
                          />
                          <p>on</p>
                          <SelectMenu
                            disabled={form.values.execute_every_x_days === 'once'}
                            options={days.map((day) => ({ label: day, value: day }))}
                            {...form.getInputProps('execute_day_of_week')}
                            className="h-[36px]"
                            width={120}
                            popoverBaseProps={{ width: 170 }}
                          />
                          <p>at</p>
                          <SelectMenu
                            disabled={form.values.execute_every_x_days === 'once'}
                            options={generateTimeOptions()}
                            {...form.getInputProps('execute_time_of_day')}
                            className="h-[36px]"
                            width={130}
                            popoverBaseProps={{ width: 170 }}
                          />
                        </div>
                      </div>
                    </div>
                    {form.values.execute_every_x_days !== 'once' && (
                      <div className="mx-auto mt-14 flex w-fit items-center justify-center space-x-2 rounded-md bg-accent-600/5 px-3 py-2">
                        <Checkbox {...form.getInputProps('execute_now', { type: 'checkbox' })} />
                        <p className="text-sm">
                          Receive the first report now. All future reports will arrive at your
                          chosen date and time.
                        </p>
                      </div>
                    )}
                  </div>
                </Stepper.Step>
              )}
              <Stepper.Step
                label={`Step ${lastStepWords}`}
                description="Success"
                allowStepSelect={false}
              >
                <div className=" mx-auto min-h-[450px] max-w-lg">
                  <p className="py-6 text-center text-lg">
                    <span className="font-semibold">Step {lastStepWords}</span>: Success
                  </p>
                  <div className="check-background mx-auto size-20 text-center">
                    <svg viewBox="0 0 65 51" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M7 25L27.3077 44L58.5 7"
                        stroke="white"
                        strokeWidth="10"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </div>
                  <p className="py-6 text-center text-sm">
                    {data?.steps?.slice(-1)[0].description ?? ''}
                  </p>
                </div>
              </Stepper.Step>
            </Stepper>
            <div className="flex justify-center space-x-2">
              {activeStep > 0 && activeStep < noOfSteps - 1 && (
                <Button variant="default" onClick={prevStep} type="button">
                  Back
                </Button>
              )}
              {activeStep < noOfSteps - 2 && (
                <Button onClick={nextStep} type="button">
                  Next step
                </Button>
              )}
              {activeStep === noOfSteps - 2 && (
                <Button type="submit" loading={isSubmitting}>
                  Create Report
                </Button>
              )}
              {activeStep === noOfSteps - 1 && (
                <Button onClick={close} type="button">
                  Close
                </Button>
              )}
            </div>
          </form>
        </FormProvider>
      </Provider>
    )
  },
  {
    fallback: ({ error, componentStack }) => {
      console.error({ error })
      return <ErrorFallback title="Form Error" />
    },
  }
)

export const Fields = ({ field, reportType }: { field: Field; reportType: string }) => {
  const form = useFormContext()
  const setLabelValue = useSetAtom(labelValueAtom)
  return (
    <div>
      {field.ui_element === 'input' ? (
        <TextInput
          required={field.required}
          placeholder={field.placeholder}
          label={field.display_name}
          {...form.getInputProps(field.field_name)}
        />
      ) : null}
      {field.ui_element === 'select' && (
        <SelectMenu
          label={field.display_name}
          required={field.required}
          placeholder={field.placeholder}
          options={field.options?.values ?? []}
          {...form.getInputProps(field.field_name)}
          onChange={(value) => {
            form.setFieldValue(field.field_name, value)
            setLabelValue((prev) => ({
              ...prev,
              [field.field_name]: field.options?.values?.find((o) => o.value === value)?.label,
            }))
          }}
          // placeholder="Category"
          width={'100%'}
        />
      )}
      {field.ui_element === 'multi_select' ? (
        field.field_name === 'ig_handle' ? (
          <MultiSelectIG
            reportType={reportType}
            field_name={field.field_name}
            required={field.required}
            label={field.display_name}
            placeholder={field.placeholder}
          />
        ) : field.field_name === 'c4_ce_category' ? (
          <MultiLevelSelectNP
            reportType={reportType}
            field_name={field.field_name}
            required={field.required}
            label={field.display_name}
            placeholder={field.placeholder}
          />
        ) : (
          <MultiSelectNP
            reportType={reportType}
            field_name={field.field_name}
            required={field.required}
            label={field.display_name}
            placeholder={field.placeholder}
          />
        )
      ) : null}
      {field.ui_element === 'select_dynamic' && (
        <SelectDynamic
          reportType={reportType}
          field_name={field.field_name}
          required={field.required}
          label={field.display_name}
          placeholder={field.placeholder}
          params={
            field.options?.api?.body?.params ? Object.keys(field.options?.api?.body?.params) : []
          }
        />
      )}
      {field.ui_element === 'textarea' && (
        <Textarea
          required={field.required}
          placeholder={field.placeholder}
          label={
            <span>
              {field.display_name}
              {field.additional_info && (
                <span className="text-xs text-gray-500"> - {field.additional_info}</span>
              )}
            </span>
          }
          rows={4}
          {...form.getInputProps(field.field_name)}
        />
      )}
      {field.ui_element === 'autocomplete' ? (
        reportType === 'BRAND__V1' ? (
          <BrandSelect
            reportType={reportType}
            field_name={field.field_name}
            required={field.required}
            label={
              <span>
                {field.display_name}
                {field.additional_info && (
                  <span className="text-xs text-gray-500"> - {field.additional_info}</span>
                )}
              </span>
            }
            placeholder={field.placeholder}
          />
        ) : (
          <AutoComplete
            reportType={reportType}
            required={field.required}
            placeholder={field.placeholder}
            field_name={field.field_name}
            label={field.display_name}
          />
        )
      ) : null}
    </div>
  )
}

export const AutoComplete = (
  props: { field_name: string; required: boolean; reportType: string } & Omit<
    AutocompleteProps & React.RefAttributes<HTMLInputElement>,
    'data'
  >
) => {
  const form = useFormContext()
  const combobox = useCombobox({
    onDropdownClose: () => combobox.resetSelectedOption(),
  })
  const [searchValue, onSearchChange] = useSearchValueAtom(searchValueAtom)
  const debouncedQuery = useDebounce(searchValue, 200)

  const setLabelValue = useSetAtom(labelValueAtom)

  const { field_name, required, reportType, label, placeholder, ...rest } = props

  const { data, isFetching } = trpc.report.reportOptions.useQuery(
    {
      report_type: reportType,
      report_geo: form.values.report_geo,
      field_name: field_name,
      q: debouncedQuery,
    },
    {
      enabled: !!debouncedQuery && !!form.values.report_geo,
      keepPreviousData: true,
    }
  )
  const options = useMemo(
    () => data?.options.map(({ display_name, value }) => ({ value, label: display_name })) ?? [],
    [data?.options]
  )

  return (
    <>
      <Combobox
        onOptionSubmit={(value) => {
          form.setFieldValue(field_name, value)
          const label = options.find((o) => o.value === value)?.label
          if (label) {
            onSearchChange(label)
          }
          setLabelValue((prev) => ({ ...prev, [field_name]: label }))
          combobox.closeDropdown()
        }}
        withinPortal={false}
        store={combobox}
      >
        <Combobox.Target>
          <TextInput
            required={required}
            label={label}
            placeholder={placeholder}
            {...form.getInputProps(field_name)}
            value={searchValue}
            onChange={(event) => {
              const q = event.currentTarget.value
              const label = options.find((o) => o.value === q)?.label
              onSearchChange(label ?? q)
              combobox.resetSelectedOption()
              combobox.openDropdown()
            }}
            onClick={() => combobox.openDropdown()}
            onFocus={() => {
              combobox.openDropdown()
            }}
            onBlur={() => combobox.closeDropdown()}
            rightSection={isFetching ? <Loader className="size-4 border-2" /> : undefined}
          />
        </Combobox.Target>

        <Combobox.Dropdown hidden={data === null}>
          <Combobox.Options mah={250} style={{ overflowY: 'auto' }}>
            {options.length > 0 ? (
              (options || []).map((item) => (
                <Combobox.Option value={item.value} key={item.value}>
                  {item.label}
                </Combobox.Option>
              ))
            ) : (
              <Combobox.Empty>No results found</Combobox.Empty>
            )}
          </Combobox.Options>
        </Combobox.Dropdown>
      </Combobox>
    </>
  )
}

export const MultiSelectNP = ({
  placeholder,
  field_name,
  label,
  reportType,
  required,
}: {
  field_name: string
  placeholder?: string
  required?: boolean
  reportType: string
  label: string
}) => {
  const form = useFormContext()
  const setLabelValue = useSetAtom(labelValueAtom)

  const { data, isFetching } = trpc.report.reportOptions.useQuery(
    {
      report_type: reportType,
      field_name: field_name,
      report_geo: form.values.report_geo,
      fields: [{ field_name: 'c2_category', value: form.values.c2_category }],
    },
    {
      keepPreviousData: true,
      enabled: !!form.values.c2_category && !!form.values.report_geo,
    }
  )

  const options = useMemo(
    () => data?.options.map(({ display_name, value }) => ({ value, label: display_name })) ?? [],
    [data?.options]
  )

  return (
    <MultiSelectFilter
      label={label}
      required={required}
      placeholder={placeholder}
      options={options}
      {...form.getInputProps(field_name)}
      onChange={(value) => {
        form.setFieldValue(field_name, value)
        setLabelValue((prev) => ({
          ...prev,
          [field_name]: value.map((v) => options.find((o) => o.value === v)?.label),
        }))
      }}
      value={form.values[field_name] ?? []}
      loading={isFetching}
      width={'100%'}
      popoverBaseProps={{ withinPortal: true }}
    />
  )
}

export const MultiLevelSelectNP = ({
  placeholder,
  field_name,
  label,
  reportType,
  required,
}: {
  field_name: string
  placeholder?: string
  required?: boolean
  reportType: string
  label: string
}) => {
  const form = useFormContext()
  const setLabelValue = useSetAtom(labelValueAtom)

  const { data, isFetching } = trpc.report.reportOptions.useQuery(
    {
      report_type: reportType,
      field_name: field_name,
      report_geo: form.values.report_geo,
      fields: [{ field_name: 'c2_category', value: form.values.c2_category }],
    },
    {
      keepPreviousData: true,
      enabled: !!form.values.c2_category && !!form.values.report_geo,
    }
  )

  const options = useMemo(
    () =>
      data?.options.map(({ display_name, value, entities, ...rest }) => ({
        value,
        label: display_name,
        subOptions: entities.map(({ display_name, value, count }) => ({
          value,
          label: display_name,
          count,
        })),
        ...rest,
      })) ?? [],
    [data?.options]
  )

  return (
    <MultilevelSelect
      label={label}
      subLabel={'Product Type'}
      required={required}
      placeholder={placeholder}
      options={options}
      {...form.getInputProps(field_name)}
      onChange={(value) => {
        form.setFieldValue(field_name, value)
        setLabelValue((prev) => ({
          ...prev,
          [field_name]: value.map((v) => options.find((o) => o.value === v)?.label),
        }))
      }}
      value={form.values[field_name] ?? []}
      loading={isFetching}
      width={'100%'}
    />
  )
}

export const MultiSelectIG = ({
  placeholder,
  field_name,
  label,
  reportType,
  required,
  error,
}: {
  field_name: string
  placeholder?: string
  required?: boolean
  reportType: string
  label: string
  error?: string
}) => {
  const form = useFormContext()
  const setLabelValue = useSetAtom(labelValueAtom)
  const [searchValue, setSearchValue] = useState('')
  const debouncedQuery = useDebounce(searchValue, 200)

  const { data, isFetching } = trpc.report.reportOptions.useQuery(
    {
      report_type: reportType,
      field_name: field_name,
      report_geo: 'all',
      q: debouncedQuery,
    },
    {
      keepPreviousData: true,
      enabled: !!debouncedQuery,
      // enabled: !!form.values.report_geo,
    }
  )

  const options = useMemo(
    () =>
      data?.options.map(({ display_name, value, image }) => ({
        value: value.profile_id,
        label: value.user_name,
        image,
      })) ?? [],
    [data?.options]
  )

  return (
    <SearchableMultiSelect
      label={label}
      required={required}
      placeholder={placeholder}
      searchValue={searchValue}
      onSearchChange={setSearchValue}
      data={options}
      // renderOption={renderMultiSelectOption(options)}
      {...form.getInputProps(field_name)}
      onChange={(value) => {
        form.setFieldValue(field_name, value)
        setLabelValue((prev) => ({
          ...prev,
          [field_name]: value.map((val) => val.label),
        }))
      }}
      value={form.values[field_name] ?? []}
      isLoading={isFetching}
      // rightSection={isFetching ? <Loader className="h-4 w-4 border-2" /> : undefined}
      // width={'100%'}
      // comboboxProps={{p}}
    />
  )
}

export const SelectDynamic = ({
  placeholder,
  field_name,
  label,
  reportType,
  required,
  params = [],
}: {
  field_name: string
  placeholder?: string
  required?: boolean
  reportType: string
  label: string
  params?: string[]
}) => {
  const form = useFormContext()
  const setLabelValue = useSetAtom(labelValueAtom)

  const { data, isFetching } = trpc.report.reportOptions.useQuery(
    {
      report_type: reportType,
      field_name: field_name,
      report_geo: form.values.report_geo,
      fields: params.map((field_name) => ({
        field_name,
        value: form.values[field_name],
      })),
    },
    {
      keepPreviousData: true,
      enabled: params.every((field_name) => !!form.values[field_name]) && !!form.values.report_geo,
    }
  )

  const options = useMemo(
    () => data?.options.map(({ display_name, value }) => ({ value, label: display_name })) ?? [],
    [data?.options]
  )

  return (
    <SelectMenu
      label={label}
      required={required}
      placeholder={placeholder}
      options={options}
      {...form.getInputProps(field_name)}
      onChange={(value) => {
        form.setFieldValue(field_name, value)
        setLabelValue((prev) => ({
          ...prev,
          [field_name]: options?.find((o) => o.value === value)?.label,
        }))
      }}
      value={form.values[field_name] ?? []}
      loading={isFetching}
      width={'100%'}
      popoverBaseProps={{ withinPortal: true }}
    />
  )
}

export const BrandSelect = ({
  placeholder,
  field_name,
  label,
  reportType,
  required,
  params = [],
}: {
  field_name: string
  placeholder?: string
  required?: boolean
  reportType: string
  label: ReactNode
  params?: string[]
}) => {
  const form = useFormContext()
  const setLabelValue = useSetAtom(labelValueAtom)

  const [searchValue, onSearchChange] = useSearchValueAtom(searchValueAtom)
  const debouncedQuery = useDebounce(searchValue, 200)

  const { data, isFetching } = trpc.report.reportOptions.useQuery(
    {
      report_type: reportType,
      field_name: field_name,
      report_geo: 'de',
      q: debouncedQuery,
    },
    {
      keepPreviousData: true,
      enabled: !!debouncedQuery,
    }
  )

  const options = useMemo(
    () =>
      data?.options.map(({ display_name, value, ...rest }) => ({
        value,
        label: display_name,
        ...rest,
      })) ?? [],
    [data?.options]
  )

  return (
    <BrandSelectMenu
      label={label}
      required={required}
      placeholder={placeholder}
      options={options}
      {...form.getInputProps(field_name)}
      searchValue={searchValue}
      onSearchChange={onSearchChange}
      onChange={(value) => {
        form.setFieldValue(field_name, value)
        setLabelValue((prev) => ({
          ...prev,
          [field_name]: options?.find((o) => o.value === value)?.label,
        }))
      }}
      value={form.values[field_name] ?? ''}
      loading={isFetching}
    />
  )
}
