// const groceries = ['🍎 Apples', '🍌 Bananas', '🥦 Broccoli', '🥕 Carrots', '🍫 Chocolate']
import React, { ReactNode, useMemo } from 'react'
import { CheckIcon, XIcon } from '@heroicons/react/outline'
import { Combobox, TextInput, useCombobox } from '@mantine/core'
import { SearchMd } from 'untitled-icons'

import { Loader } from '@/components/Loader'
import { cn } from '@/util/classNames'

interface Option {
  label: string
  value: string
  count?: number
  disabled?: boolean
  [x: string]: any
}

export function BrandSelectMenu({
  value,
  onChange,
  searchValue: search,
  onSearchChange: setSearch,
  placeholder,
  options: data,
  label,
  required,
  loading,
  error,
}: {
  value: string
  onChange: (value: string) => void
  searchValue: string
  onSearchChange: (value: string) => void
  options: Option[]
  placeholder?: string
  required?: boolean
  label: ReactNode
  loading?: boolean
  error?: string
}) {
  const combobox = useCombobox({
    onDropdownClose: () => combobox.resetSelectedOption(),
    onDropdownOpen: () => combobox.updateSelectedOptionIndex('active'),
  })

  const options = useMemo(
    () =>
      data.map((option) => {
        const isSelected = value === option.value
        return (
          <Combobox.Option
            value={option.value}
            key={option.value}
            active={isSelected}
            component={'tr'}
            className={cn(
              'w-full rounded-md border-b border-dotted border-gray-200 hover:cursor-pointer hover:bg-gray-50',
              isSelected && 'bg-gray-50 font-medium',
              option.disabled && 'pointer-events-none text-gray-500'
            )}
          >
            <td className="relative py-3.5 pl-10 pr-4 ">
              {isSelected && (
                <div className="absolute inset-y-0 left-3 flex items-center ">
                  <CheckIcon className="size-5 text-accent-600" />
                </div>
              )}

              <div className="truncate">{option?.label}</div>
            </td>
            <td className="">
              <div className="flex justify-center ">
                {option?.has_ci_data ? (
                  <div className="rounded-full border border-gray-200 p-1.5">
                    <CheckIcon className="size-4" />
                  </div>
                ) : (
                  <div className="rounded-full border border-gray-200 p-1.5">
                    <XIcon className="size-4 text-red-500" />
                  </div>
                )}
              </div>
            </td>
            <td className="">
              <div className="flex justify-center">
                {option?.has_ecom_data ? (
                  <div className="rounded-full border border-gray-200 p-1.5">
                    <CheckIcon className="size-4" />
                  </div>
                ) : (
                  <div className="rounded-full border border-gray-200 p-1.5">
                    <XIcon className="size-4 text-red-500" />
                  </div>
                )}
              </div>
            </td>
          </Combobox.Option>
        )
      }),
    [data, value]
  )

  return (
    <div>
      <Combobox
        store={combobox}
        onOptionSubmit={(optionValue) => {
          onChange(optionValue)
          setSearch(data.find((o) => o.value === optionValue)?.label || '')
          combobox.closeDropdown()
        }}
        withinPortal={false}
      >
        <Combobox.DropdownTarget>
          <TextInput
            label={
              <>
                {label} {required && <span className="text-red-500">*</span>}
              </>
            }
            placeholder={placeholder}
            value={search}
            onChange={(event) => {
              combobox.updateSelectedOptionIndex()
              setSearch(event.currentTarget.value)
            }}
            onClick={() => combobox.openDropdown()}
            onFocus={() => {
              combobox.openDropdown()
            }}
            onBlur={() => combobox.closeDropdown()}
            leftSection={<SearchMd className="size-4 text-gray-500" />}
            rightSection={
              loading ? <Loader className="float-right mt-px size-4 border-2" /> : undefined
            }
          />
        </Combobox.DropdownTarget>

        <Combobox.Dropdown hidden={loading && options.length === 0}>
          <Combobox.Options mah={320} style={{ overflowY: 'auto' }}>
            {options.length > 0 ? (
              <table className="w-full space-y-1 ">
                <thead className="border-b border-dotted border-gray-200 pb-4">
                  <tr>
                    <th className="py-4  pl-10 text-left  font-medium">Brand</th>
                    <th className="py-4 text-center text-xs font-normal text-gray-500">
                      Consumer Data
                    </th>
                    <th className="py-4 text-center text-xs font-normal text-gray-500">
                      Market Data
                    </th>
                  </tr>
                </thead>
                {options}
              </table>
            ) : (
              <Combobox.Empty>Nothing found...</Combobox.Empty>
            )}
          </Combobox.Options>
        </Combobox.Dropdown>
      </Combobox>
    </div>
  )
}
