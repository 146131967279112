import * as React from 'react'
import {
  autoUpdate,
  flip,
  FloatingDelayGroup,
  FloatingPortal,
  offset,
  safePolygon,
  shift,
  useDelayGroup,
  useDelayGroupContext,
  useDismiss,
  useFloating,
  useFocus,
  useHover,
  useId,
  useInteractions,
  useMergeRefs,
  useRole,
  useTransitionStyles,
  type Placement,
} from '@floating-ui/react'
import { LayoutGroup, motion } from 'framer-motion'
import { Link, To } from 'react-router-dom'

import { cn } from '@/util/classNames'

const TooltipGroupContext = React.createContext<{
  groupId: string | undefined
  placement: Placement
}>({
  groupId: undefined,
  placement: 'top',
})

export type TooltipGroupProps = {
  showDelay?: number
  hideDelay?: number
  timeout?: number
  placement?: Placement
  children: React.ReactNode
}

export const TooltipGroup = ({
  showDelay = 600,
  hideDelay = 200,
  timeout = 300,
  placement = 'top',
  children,
}: TooltipGroupProps) => {
  const groupId = useId()

  return (
    <TooltipGroupContext.Provider value={{ groupId, placement }}>
      <FloatingDelayGroup delay={{ open: showDelay, close: hideDelay }} timeoutMs={timeout}>
        <LayoutGroup>{children}</LayoutGroup>
      </FloatingDelayGroup>
    </TooltipGroupContext.Provider>
  )
}

interface TooltipOptions {
  showDelay?: number
  hideDelay?: number
  initialOpen?: boolean
  placement?: Placement
  open?: boolean
  onOpenChange?: (open: boolean) => void
}

export function useTooltip({
  initialOpen = false,
  placement = 'bottom-start',

  open: controlledOpen,

  onOpenChange: setControlledOpen,
}: TooltipOptions = {}) {
  const { placement: groupPlacement, groupId } = React.useContext(TooltipGroupContext)
  const [uncontrolledOpen, setUncontrolledOpen] = React.useState(initialOpen)

  const open = controlledOpen ?? uncontrolledOpen
  const setOpen = setControlledOpen ?? setUncontrolledOpen

  const { delay, isInstantPhase } = useDelayGroupContext()

  const placementFinal = placement || groupPlacement

  const data = useFloating({
    placement: placementFinal,
    open,
    onOpenChange: setOpen,
    whileElementsMounted: autoUpdate,
    middleware: [offset(5), flip(), shift()],
  })

  const context = data.context

  const hover = useHover(context, {
    // move: false,
    enabled: controlledOpen == null,
    delay: delay,
    handleClose: safePolygon({
      requireIntent: false,
      // blockPointerEvents: true,
      buffer: 1,
    }),
  })
  const focus = useFocus(context, {
    enabled: controlledOpen == null,
  })
  const dismiss = useDismiss(context)
  const role = useRole(context, { role: 'menu' })

  const interactions = useInteractions([hover, focus, dismiss, role])

  return React.useMemo(
    () => ({
      open,
      setOpen,
      ...interactions,
      ...data,
    }),
    [open, setOpen, interactions, data]
  )
}

type ContextType = ReturnType<typeof useTooltip> | null

const TooltipContext = React.createContext<ContextType>(null)

export const useTooltipState = () => {
  const context = React.useContext(TooltipContext)

  if (context == null) {
    throw new Error('Tooltip components must be wrapped in <Tooltip />')
  }

  return context
}

export function Tooltip({ children, ...options }: { children: React.ReactNode } & TooltipOptions) {
  // This can accept any props as options, e.g. `placement`,
  // or other positioning options.
  const tooltip = useTooltip(options)
  return <TooltipContext.Provider value={tooltip}>{children}</TooltipContext.Provider>
}

export const TooltipTrigger = React.forwardRef<
  HTMLElement,
  React.HTMLProps<HTMLElement> & { asChild?: boolean; isActive?: boolean }
>(function TooltipTrigger({ children, asChild = false, isActive, ...props }, propRef) {
  const state = useTooltipState()

  const childrenRef = (children as any).ref
  const ref = useMergeRefs([state.refs.setReference, propRef, childrenRef])

  // `asChild` allows the user to pass any element as the anchor
  if (asChild && React.isValidElement(children)) {
    return React.cloneElement(
      children,
      state.getReferenceProps({
        ref,
        ...props,
        ...children.props,
        'data-state': state.open ? 'open' : 'closed',
      })
    )
  }

  return (
    <button
      ref={ref}
      // The user can style the trigger based on the state
      data-state={state.open ? 'open' : 'closed'}
      {...state.getReferenceProps(props)}
      className={cn(
        'relative px-4 py-2 text-sm font-semibold'
        // s.tab,
        // (state.open || isActive) && s['is-active']
      )}
    >
      {(state.open || isActive) && <Aidv />}
      {children}
    </button>
  )
})

export const Aidv = () => {
  return (
    <motion.div
      className="absolute inset-0 -z-10 bg-gray-200"
      layoutId="underline"
      style={{ borderRadius: 4 }}
      transition={{ type: 'spring', bounce: 0.2, duration: 0.6 }}
    ></motion.div>
  )
}

export const HoverButton = ({
  children,
  to,
  isActive,
}: React.HTMLProps<HTMLElement> & { to: To; isActive?: boolean }) => {
  const [hover, setHover] = React.useState(false)
  return (
    <Link
      to={to}
      className={cn('relative px-4 py-2 text-sm font-semibold')}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
    >
      {(hover || isActive) && <Aidv />}
      {children}
    </Link>
  )
}

const container = {
  hidden: { opacity: 0 },
  show: {
    opacity: 1,
    transition: {
      staggerChildren: 0.5,
    },
  },
}

export const TooltipContent = React.forwardRef<HTMLDivElement, React.HTMLProps<HTMLDivElement>>(
  function TooltipContent(props, propRef) {
    const { children, className, ...rest } = props
    const state = useTooltipState()
    const id = useId()
    const { isInstantPhase, currentId } = useDelayGroupContext()
    const ref = useMergeRefs([state.refs.setFloating, propRef])

    useDelayGroup(state.context, { id })

    const instantDuration = 0
    const duration = 250

    const { isMounted, styles } = useTransitionStyles(state.context, {
      duration: isInstantPhase
        ? {
            open: instantDuration,
            // `id` is this component's `id`
            // `currentId` is the current group's `id`
            close: currentId === id ? duration : instantDuration,
          }
        : duration,
      initial: {
        opacity: 0,
      },
    })

    const computedPlacement = state.placement ?? 'bottom'

    const translate = {
      top: { translateY: 5 },
      bottom: { translateY: -10 },
      left: { translateX: 5 },
      right: { translateX: -5 },
    }[
      computedPlacement.includes('-')
        ? computedPlacement.split('-')[0] ?? 'bottom'
        : computedPlacement
    ]

    const variants = {
      hidden: isInstantPhase ? {} : { opacity: 0, ...translate },
      show: {
        opacity: 1,
        translateX: 0,
        translateY: 0,
        transition: {
          duration: 0.25,
          staggerChildren: 0.1,
        },
      },
    }

    return (
      <FloatingPortal id="nav-bar">
        {/* <AnimatePresence> */}
        {isMounted && (
          <motion.div
            layout
            layoutId="menu"
            {...state.getFloatingProps({
              ref,
              className: 'Tooltip',
              style: {
                position: state.strategy,
                top: state.y ?? 0,
                left: state.x ?? 0,
                // width: 400,
              },
            })}
            variants={variants}
            initial="hidden"
            animate="show"
            // initial={isInstantPhase ? {} : { opacity: 0, ...translate }}
            // animate={{
            //   opacity: 1,
            //   translateX: 0,
            //   translateY: 0,
            //   transition: {
            //     staggerChildren: 0.1,
            //     staggerDirection: 1,
            //     delayChildren: 0.2,
            //     duration: 0.2,
            //   },
            // }}
            // exit={{ opacity: 0, ...translate }}
            transition={{
              duration: 0.2,
              delayChildren: 0.2,
              staggerChildren: 0.1,
              staggerDirection: 1,
            }}
            className={cn(
              'z-[5000] rounded-md border border-gray-200 bg-white shadow-md',
              className
            )}
          >
            {children}
          </motion.div>
        )}
        {/* </AnimatePresence> */}
      </FloatingPortal>
    )
  }
)
