import React from 'react'
import { Checkbox } from '@mantine/core'
import { difference, union, xor } from 'lodash'
import { ChevronDownIcon } from 'lucide-react'

import { cn } from '@/util/classNames'
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuPortal,
  DropdownMenuSub,
  DropdownMenuSubContent,
  DropdownMenuSubTrigger,
  DropdownMenuTrigger,
} from './DropdownMenu'
import { Loader } from './Loader'

export type Option = {
  parent?: string
  label: string
  value: string
  count?: number
  subOptions?: Option[]
}

type Props = {
  options: Option[]
  value: string[]
  placeholder?: string
  error?: string
  label?: string
  subLabel?: string
  required?: boolean
  loading?: boolean
  onChange: (value: string[]) => void
  width?: React.CSSProperties['width']
  searchable?: boolean
  disabled?: boolean
}

export const MultilevelSelect: React.FunctionComponent<React.PropsWithChildren<Props>> = ({
  options = [],
  value,
  label,
  subLabel,
  error,
  required,
  placeholder,
  onChange,
  loading,
  width = 250,
  searchable,
  disabled,
}) => {
  return (
    <div>
      {label && (
        <p className="text-sm font-medium">
          {label} {required && <span className="text-red-600">*</span>}
        </p>
      )}
      <DropdownMenu>
        <DropdownMenuTrigger asChild>
          <button
            className={cn(
              'input my-auto flex min-h-[38px] items-center justify-between truncate rounded-md bg-white px-3 py-2 text-sm capitalize',
              disabled && 'pointer-events-none border-gray-200 text-gray-500 ',
              error && 'border-red-500',
              loading && 'pointer-events-none'
            )}
            style={{ width }}
            type="button"
          >
            <div className="-m-1 flex flex-wrap">
              {placeholder && <span className="text-primary-700">{placeholder}</span>}
            </div>
            {!disabled &&
              (loading ? (
                <Loader className="size-4 border-2" />
              ) : (
                <ChevronDownIcon className="float-right mt-px size-4 text-gray-600" />
              ))}
          </button>
        </DropdownMenuTrigger>

        {options.length > 0 && (
          <DropdownMenuContent
            className="z-[201] max-h-[300px] overflow-y-auto"
            style={{ width: 'var(--radix-dropdown-menu-trigger-width)' }}
          >
            <DropdownMenuLabel className="flex items-center justify-between">
              <p> {label}</p>
              <button
                onClick={() => {
                  onChange(
                    union(
                      value,
                      ...options.map((option) =>
                        option.subOptions ? option.subOptions.map((o) => o.value) : []
                      )
                    )
                  )
                }}
                className="text-accent-600 underline underline-offset-1"
              >
                Select All
              </button>
            </DropdownMenuLabel>
            {options.map((option, index) => {
              return option.subOptions ? (
                <DropdownMenuSub>
                  <DropdownMenuSubTrigger key={index}>
                    <SelectItem
                      option={option}
                      checked={
                        difference(
                          option.subOptions.map((o) => o.value),
                          value
                        ).length === 0
                      }
                      onChange={(e) => {
                        if (!value.includes(option.value)) {
                          onChange(
                            union(value, [
                              ...(option.subOptions ? option.subOptions.map((o) => o.value) : []),
                            ])
                          )
                        } else {
                          onChange(
                            difference(value, [
                              ...(option.subOptions ? option.subOptions.map((o) => o.value) : []),
                            ])
                          )
                        }
                      }}
                    />
                  </DropdownMenuSubTrigger>
                  <DropdownMenuPortal>
                    <DropdownMenuSubContent className="z-[201] max-h-[300px] w-[250px] overflow-y-auto">
                      <DropdownMenuLabel className="flex items-center justify-between">
                        <p>{subLabel}</p>
                        <button
                          onClick={() => {
                            onChange(
                              union(
                                value,
                                option.subOptions?.map((option) => option.value)
                              )
                            )
                          }}
                          className="text-accent-600 underline underline-offset-1"
                        >
                          Select All
                        </button>
                      </DropdownMenuLabel>
                      {option.subOptions.map((option, index) => {
                        return (
                          <DropdownMenuItem
                            key={index}
                            onSelect={(e) => {
                              e.preventDefault()
                            }}
                          >
                            <SelectItem
                              option={option}
                              checked={value.includes(option.value)}
                              onChange={(e) => {
                                onChange(xor(value, [option.value]))
                              }}
                            />
                          </DropdownMenuItem>
                        )
                      })}
                    </DropdownMenuSubContent>
                  </DropdownMenuPortal>
                </DropdownMenuSub>
              ) : (
                <DropdownMenuItem
                  key={index}
                  onSelect={(e) => {
                    e.preventDefault()
                  }}
                >
                  <SelectItem
                    option={option}
                    checked={value.includes(option.value)}
                    onChange={(e) => {
                      onChange(xor(value, [option.value]))
                    }}
                  />
                </DropdownMenuItem>
              )
            })}
          </DropdownMenuContent>
        )}
      </DropdownMenu>
      {error && <p className="text-xs text-red-500">{error}</p>}
    </div>
  )
}

export const SelectItem = ({ option, checked, onChange }) => {
  return (
    <div className={cn('flex items-center space-x-2 rounded-md')}>
      <Checkbox
        checked={checked}
        size="xs"
        id={`filterOption-${option.value}`}
        onChange={(e) => {
          onChange()
        }}
      />
      <label
        htmlFor={`filterOption-${option.value}`}
        className={cn(
          'flex w-full cursor-pointer items-center justify-between space-x-2 truncate text-sm'
        )}
      >
        <div className="truncate">{option?.label}</div>
      </label>
    </div>
  )
}
