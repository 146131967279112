import * as React from 'react'

function AlignLeft(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
      {...props}
    >
      <path d="M17 10H3M21 6H3M21 14H3M17 18H3" />
    </svg>
  )
}

const AlignLeftIcon = React.memo(AlignLeft)
export default AlignLeftIcon
