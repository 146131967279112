import { AlertCircle } from 'untitled-icons'

export const ErrorCard: React.FunctionComponent<
  React.PropsWithChildren<{ height?: React.CSSProperties['height'] }>
> = ({ height }) => {
  return (
    <div
      className="m-auto flex flex-col items-center justify-center rounded-md bg-white text-center"
      style={{ height: height ?? 350 }}
    >
      <AlertCircle className="size-12 rounded-full border-4 border-yellow-50 bg-yellow-100 p-2 text-yellow-700" />
      <div className="mt-2 max-w-prose space-y-1">
        <p className="text-base font-semibold text-gray-800">Something went wrong...</p>
        <p className="text-sm font-medium text-gray-600">
          We had some trouble loading this page. Please refresh the page to try again or get in
          touch if the problem sticks around!
        </p>
      </div>
    </div>
  )
}
