import React, { createContext, useContext } from 'react'
import { DatabaseIcon } from '@heroicons/react/outline'
import { DownloadIcon, LockClosedIcon, PhotographIcon } from '@heroicons/react/solid'
import { Menu } from '@mantine/core'
// import exportFromJSON from 'export-from-json'
import { toPng } from 'html-to-image'
import xlsx from 'json-as-xlsx'

import { Loader } from './Loader'

type Props = {
  fileName?: string
  exportDelay?: number
  text?: React.ReactNode
  exportData?: Record<string, any> | (() => Promise<Record<string, any>>)
}

interface ExportMenuContext {
  exporting: boolean
  setExporting: (exporting: boolean) => void
}

export const ExportMenuContext = createContext<ExportMenuContext>({} as ExportMenuContext)

export const ExportMenuProvider = ({ children }) => {
  const [exporting, setExporting] = React.useState(false)
  return (
    <ExportMenuContext.Provider value={{ exporting, setExporting }}>
      {children}
    </ExportMenuContext.Provider>
  )
}

export const ExportMenu = React.forwardRef<any, Props>(
  ({ text, fileName = 'download', exportDelay = 0, exportData: exportData }, ref) => {
    const [loading, setLoading] = React.useState(false)
    const exportMenu = useContext(ExportMenuContext)
    const downloadImage = () => {
      if (ref != null && typeof ref !== 'function') {
        exportMenu?.setExporting?.(true)
        setTimeout(() => {
          toPng(ref.current, { cacheBust: true, backgroundColor: '#fff' })
            .then((dataUrl) => {
              const link = document.createElement('a')
              link.download = fileName
              link.href = dataUrl
              link.click()
            })
            .then(() => exportMenu?.setExporting?.(false))
        }, exportDelay)
      }
    }

    return (
      <Menu width={208} position="bottom-end">
        <Menu.Target>
          <div className="flex items-center space-x-1">
            {text}
            <DownloadIcon className="size-5 cursor-pointer text-gray-400 hover:text-accent-600" />
          </div>
        </Menu.Target>
        <Menu.Dropdown>
          <Menu.Label className="text-base font-semibold">Export</Menu.Label>
          <Menu.Item leftSection={<PhotographIcon className="size-4" />} onClick={downloadImage}>
            Export as png
          </Menu.Item>
          <Menu.Item
            disabled={!exportData || loading}
            leftSection={
              loading ? (
                <Loader className="size-4 border-2" />
              ) : exportData ? (
                <DatabaseIcon className="size-4" />
              ) : (
                <LockClosedIcon className="size-4" />
              )
            }
            onClick={async () => {
              if (!exportData) return
              let data: any
              if (typeof exportData === 'function') {
                // This is the only reliable way to check if a function is asynchronous
                const onClickIsPromise = exportData.constructor.name === 'AsyncFunction'
                // We only set loading if the function is actually async
                // to avoid useless re-renders
                if (onClickIsPromise) setLoading(true)
                // We can await onclick even if it's not asynchronous
                // it won't change its behavior
                data = await exportData()
                if (onClickIsPromise) setLoading(false)
              } else {
                data = exportData
              }
              //   const data = typeof exportData === 'function' ? await exportData() : exportData
              xlsx(
                Array.isArray(data)
                  ? [
                      {
                        sheet: fileName,
                        columns: Object.keys(data[0]).map((i) => ({ label: i, value: i })),
                        content: data,
                      },
                    ]
                  : Object.keys(data).map((i) => ({
                      sheet: i,
                      columns: Object.keys(data[i][0]).map((i) => ({ label: i, value: i })),
                      content: data[i],
                    })),
                { fileName }
              )
              // exportFromJSON({
              //   data,
              //   fileName,
              //   exportType: exportFromJSON.types.csv,
              // })
            }}
          >
            Export as xlsx
          </Menu.Item>
        </Menu.Dropdown>
      </Menu>
    )
  }
)

ExportMenu.displayName = 'ExportMenu'
