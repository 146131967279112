import React, { ReactNode, useState } from 'react'
import { Modal, ModalProps } from '@mantine/core'

import { cn } from '@/util/classNames'

export const VideoModal = ({
  children,
  href,
  className,
  onClick,
  modalProps,
}: {
  children: ReactNode
  href: string
  className?: string
  onClick?: () => void
  modalProps?: Omit<ModalProps, 'opened' | 'onClose'>
}) => {
  const [modalOpen, setModalOpen] = useState(false)
  return (
    <>
      <Modal
        opened={modalOpen}
        onClose={() => setModalOpen(false)}
        size="900px"
        padding={0}
        withCloseButton={false}
        centered
        {...modalProps}
      >
        {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
        <video width={900} height="auto" controls>
          <source src={href} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      </Modal>
      <button
        onClick={() => {
          setModalOpen(true)
          onClick && onClick()
        }}
        className={cn('text-left', className)}
      >
        {children}
      </button>
    </>
  )
}
