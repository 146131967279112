import React from 'react'
import { XIcon } from '@heroicons/react/solid'
import { Button, Modal } from '@mantine/core'
import { useDisclosure } from '@mantine/hooks'
import * as Sentry from '@sentry/react'
import { Swiper, SwiperSlide } from 'swiper/react'

import { ErrorFallback } from '@/components/ErrorFallback'
import { DataLoader } from '@/components/Loader'
import { cn } from '@/util/classNames'
import { trpc } from '@/util/trpc'
import { SliderNavigation } from '../_components/SliderNavigation'
import { TopBar } from '../_components/TopBar'
import RecentReports from './_components/RecentReports'
import { StepperForm } from './_components/StepperForm'

const templates = [
  {
    title: 'Google Insights Report',
    thumbnail: ['/ice/webp/ci-1.webp', '/ice/webp/ci-2.webp'],
    reportType: 'CI__V2',
    color: '#F7F8FB',
    section: 'CI',
  },
  {
    title: 'Brand Report',
    thumbnail: ['/ice/webp/brand-1.webp'],
    reportType: 'BRAND__V1',
    color: '#E4E6EA',
    section: 'BRAND',
  },
  {
    title: 'QVC Report',
    thumbnail: ['/ice/webp/qvc-1.webp'],
    reportType: 'QVC__V1',
    color: '#F5EDED',
    section: 'QVC',
  },
  {
    title: 'HSE Report',
    thumbnail: ['/ice/webp/hse-1.webp'],
    reportType: 'HSE__V1',
    color: '#F9E7E5',
    section: 'HSE',
  },
  {
    title: (
      <>
        Instagram Summary <br></br> Report
      </>
    ),
    thumbnail: ['/ice/webp/ig-1.webp'],
    reportType: 'IG__V1',
    color: '#F3EDF5',
    section: 'IG',
  },
  {
    title: 'Amazon Bestselling Products',
    thumbnail: ['/ice/webp/az-1.webp'],
    reportType: 'AZ__V2',
    color: '#E4E6EA',
    section: 'AZ',
  },
  {
    title: 'Product Performance Summary',
    thumbnail: ['/ice/webp/pp-1.webp'],
    reportType: 'PP__V2',
    color: '#F1F5ED',
    section: 'PP',
  },
  {
    title: 'New Products Report',
    thumbnail: ['/ice/webp/mi-1.webp', '/ice/webp/mi-2.webp'],
    reportType: 'NP__V2',
    color: '#F5F3ED',
    section: 'MI',
  },
]

export function Page() {
  return (
    <>
      <TopBar />
      <div className="border-b border-primary-200">
        <div className="max-w-layout mt-2 space-y-3 p-8">
          <h2 className="text-lg font-semibold">AI Reports</h2>

          <Swiper slidesPerView={5} spaceBetween={15} slidesPerGroup={5}>
            {templates.map((template, index) => (
              <SwiperSlide key={index}>
                <div
                  className=" rounded-md px-6 py-5"
                  key={index}
                  style={{ background: template.color }}
                >
                  {/* <div
                    className="w-fit rounded-md px-2 py-1 text-sm font-semibold text-accent-600"
                    style={{ background: darken(template.color, 0.1) }}
                  >
                    {template.section}
                  </div> */}
                  <p
                    className={cn(
                      'line-clamp-2 text-center text-base font-medium capitalize text-black ',
                      template?.thumbnail[1] ? '' : 'h-[48px]'
                    )}
                  >
                    {template?.title}
                  </p>
                  <div
                    className={cn(
                      'group relative mx-auto mt-4 w-full',
                      template?.thumbnail[1] ? 'h-[140px]' : 'h-[117px]'
                    )}
                  >
                    {template?.thumbnail[1] && (
                      <div className="absolute inset-x-0 top-0 z-0 mx-auto px-7 transition duration-300 ease-in-out group-hover:-translate-y-1">
                        <img
                          src={template?.thumbnail[1]}
                          alt="report-thumbnail"
                          className={'max-h-full rounded-md object-contain '}
                        />
                      </div>
                    )}
                    <img
                      src={template?.thumbnail[0]}
                      alt="report-thumbnail"
                      className={cn(
                        'absolute inset-x-0 bottom-0 z-10 mx-auto max-h-full rounded-md transition duration-300 ease-in-out group-hover:translate-y-1.5'
                        // template?.thumbnail[1] ? 'pt-8' : 'pt-0'
                      )}
                    />
                  </div>

                  <GnerateReportModal reportType={template.reportType}>
                    {(open) => (
                      <div className="mt-6 flex justify-center">
                        <Button variant="default" onClick={() => open()}>
                          Request Report
                        </Button>
                      </div>
                    )}
                  </GnerateReportModal>
                </div>
              </SwiperSlide>
            ))}

            <SliderNavigation />
          </Swiper>
        </div>
      </div>
      <RecentReports />
    </>
  )
}

export default Sentry.withErrorBoundary(Page, {
  fallback: ({ error, componentStack }) => {
    console.error({ error })
    return <ErrorFallback title="ICE" />
  },
})

export const GnerateReportModal = ({
  reportType,
  children,
}: {
  reportType: string
  children: (open: () => void) => void
}) => {
  const [opened, { open, close }] = useDisclosure(false)
  const { data, isLoading } = trpc.report.reportInfo.useQuery(
    {
      report_type: reportType,
    },
    {
      enabled: !!reportType,
    }
  )

  return (
    <>
      <Modal
        opened={opened}
        onClose={close}
        size="80%"
        withCloseButton={false}
        centered
        padding={34}
        styles={{
          body: { padding: '30px 45px' },
        }}
        radius="lg"
      >
        <div className="space-y-8">
          <div className="flex items-center justify-between">
            <h2 className="text-lg font-semibold">{data?.report_name}</h2>
            <button className="" onClick={close}>
              <XIcon className="size-5 text-primary-500" />
            </button>
          </div>
          {isLoading ? (
            <DataLoader />
          ) : (
            data && <StepperForm data={data} reportType={reportType} close={close} />
          )}
        </div>
      </Modal>
      {children(open)}
    </>
  )
}
