import * as JSURL from 'jsurl2'
import { PersistStorage } from 'zustand/middleware'

const getUrlSearch = () => {
  return window.location.search.slice(1)
}

export function createPersistentStorage<S>(): PersistStorage<S> | undefined {
  return {
    getItem: (key) => {
      // Check URL first
      const searchParams = new URLSearchParams(getUrlSearch())
      const storedValue = searchParams.get(key)
      return JSURL.parse(storedValue ?? '')
    },
    setItem: (key, newValue): void => {
      // Check if query params exist at all, can remove check if always want to set URL
      const searchParams = new URLSearchParams(getUrlSearch())
      searchParams.set(key, JSURL.stringify(newValue, { rich: true }))
      window.history.replaceState(null, window.document.title, `?${searchParams.toString()}`)

      //   localStorage.setItem(key, JSURL.stringify(newValue))
    },
    removeItem: (key): void => {
      const searchParams = new URLSearchParams(getUrlSearch())
      searchParams.delete(key)
      window.location.search = searchParams.toString()
    },
  }
}
