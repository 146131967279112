import { FunctionComponent } from 'react'

import { cn } from '@/util/classNames'

export const EmptyStatev2: FunctionComponent<
  React.PropsWithChildren<{
    children?: React.ReactNode
    className?: string
    title?: React.ReactNode
    messageTitle?: React.ReactNode
    message: React.ReactNode
    border?: boolean
    classNames?: {
      root?: string
      content?: string
    }
  }>
> = ({ title, border, messageTitle, message, className, classNames }) => {
  return (
    <div
      className={cn(
        'overflow-hidden rounded-md bg-white',
        border ? 'border border-primary-200' : '',
        className,
        classNames?.root
      )}
    >
      {title && <div className=" p-4 font-semibold">{title}</div>}
      <div
        className={cn(
          'm-auto -mt-5 flex w-[500px] h-80 flex-col items-center justify-center text-center',
          classNames?.content
        )}
      >
        <img src="/icons/no-data.svg" alt="" className="size-20 " />
        <div className="mt-2 space-y-1">
          <p className="text-base font-semibold text-gray-800">{messageTitle}</p>
          <p className="text-sm font-normal text-gray-600">{message}</p>
        </div>
      </div>
    </div>
  )
}
