import React, { ReactNode, useMemo, useRef, useState } from 'react'
import { ChevronDownIcon, XIcon } from '@heroicons/react/outline'
import { Checkbox, Popover, PopoverProps, TextInput } from '@mantine/core'
import { useVirtualizer } from '@tanstack/react-virtual'
import { groupBy, intersectionWith, orderBy, xor } from 'lodash'
import { SearchMd } from 'untitled-icons'

import { cn } from '@/util/classNames'
import { formatNumberCompact } from '@/util/formatNumber'
import { Loader } from './Loader'

export type Option = {
  label: string
  value: string
  count?: number
  group?: string
  [x: string]: any
}

export type Action = 'remove' | 'add' | 'clear'

export type MultiSelectFilterProps = {
  title?: ReactNode
  options: Option[]
  value: string[]
  inputLabel?: string
  placeholder?: string
  error?: string
  label?: string
  required?: boolean
  loading?: boolean
  onChange: (value: string[], action?: Action, addedValue?: Option) => void
  customItem?: (item: {
    isSelected: boolean
    disabled?: boolean
    option: Option
    onClick: () => void
  }) => ReactNode
  width?: React.CSSProperties['width']
  popoverWidth?: React.CSSProperties['width']
  rowSize?: number
  selectedFirst?: boolean // selected value will be shown first
  searchable?: boolean
  manualSearch?: {
    value: string
    onChange: (value: string) => void
  }
  profilesPresent?: boolean
  disabled?: boolean
  disableSort?: boolean
  popoverBaseProps?: PopoverProps
  onApply?: () => void
  opened?: boolean
  setOpened?: (opened: boolean) => void
  emptyStateMessage?: string
}

export const MultiSelectFilter: React.FunctionComponent<
  React.PropsWithChildren<MultiSelectFilterProps>
> = ({
  options = [],
  value,
  inputLabel,
  title,
  label,
  error,
  required,
  placeholder,
  onChange,
  customItem,
  loading,
  width = 250,
  popoverWidth,
  emptyStateMessage,
  searchable,
  manualSearch,
  disabled,
  disableSort,
  popoverBaseProps,
  onApply,
  selectedFirst = false,
}) => {
  const [search, setSearch] = useState('')

  const sortByCount = (arr: Option[]) => (disableSort ? arr : orderBy(arr, (o) => o?.count, 'desc'))

  const filteredOptions = useMemo(() => {
    const searchFiltered = options.filter((s) => new RegExp(search, 'i').test(s?.label ?? s.value))

    return sortByCount(searchFiltered)
  }, [search, options, value])

  const [opened, setOpened] = useState(false)

  return (
    <div
      className="space-y-1"
      style={{
        width,
      }}
    >
      {label && (
        <p className="text-sm font-medium">
          {label} {required && <span className="text-red-600">*</span>}
        </p>
      )}
      <Popover
        shadow="md"
        width={popoverWidth || 'target'}
        position="bottom-start"
        zIndex={500}
        {...popoverBaseProps}
        opened={opened}
        onChange={setOpened}
      >
        <Popover.Target>
          <button
            type="button"
            className={cn(
              'input my-auto flex h-11 items-center justify-between truncate rounded-md bg-white px-3 py-2 text-sm capitalize hover:bg-gray-50',
              disabled && 'pointer-events-none border-gray-200 text-gray-500 ',
              error && 'border-red-500',
              loading && 'pointer-events-none'
            )}
            style={{ width }}
            onClick={() => setOpened((o) => !o)}
          >
            <div className="-m-1 flex flex-wrap">
              {inputLabel ??
                (value.length > 0
                  ? intersectionWith(options, value, (option, v) => option.value === v).map(
                      (p, id) => (
                        <div
                          key={id}
                          className="m-1 flex items-center space-x-1 rounded-sm bg-primary-100 px-1 py-0.5 text-xs"
                        >
                          <p>{p.label}</p>
                          <button
                            type="button"
                            onClick={(e) => {
                              e.stopPropagation()
                              onChange(value.filter((v) => v !== p.value))
                            }}
                          >
                            <XIcon className="size-3" />
                          </button>
                        </div>
                      )
                    )
                  : placeholder && <span className="text-primary-700">{placeholder}</span>)}
            </div>
            {!disabled &&
              (loading ? (
                <Loader className="size-4 border-2" />
              ) : (
                <ChevronDownIcon className="float-right mt-px size-4 text-gray-600" />
              ))}
          </button>
        </Popover.Target>
        <Popover.Dropdown
          className="scroller-sm text-left text-sm font-normal"
          px={0}
          pt={0}
          pb={0}
        >
          <div className="">
            {title && (
              <div className="mt-3 flex justify-between px-4 font-medium">
                <p className="">{title}</p>
                <button
                  type="button"
                  className="text-accent-600 underline hover:text-accent-800"
                  onClick={() => {
                    onChange(options.map((o) => o.value))
                  }}
                >
                  Select All
                </button>
              </div>
            )}
            {(searchable || manualSearch) && (
              <TextInput
                leftSection={<SearchMd className="size-4 text-gray-500" />}
                size="md"
                placeholder="Search..."
                value={manualSearch ? manualSearch.value : search}
                onChange={(e) =>
                  manualSearch ? manualSearch.onChange(e.target.value) : setSearch(e.target.value)
                }
                styles={{
                  input: {
                    fontSize: '14px',
                  },
                }}
                className="border-b border-primary-200 p-4"
              />
            )}
          </div>
          {filteredOptions.length > 0 ? (
            <FilterList
              options={filteredOptions}
              value={value}
              onChange={onChange}
              customItem={customItem}
              selectedFirst={selectedFirst}
            />
          ) : (
            <div className="flex min-h-[260px]">
              <div className="flex items-center justify-center px-12 text-primary-700">
                <p className="text-center">
                  {emptyStateMessage ||
                    "This topic isn't in our list. Try using the search bar above the filters."}
                </p>
              </div>
            </div>
          )}
          {onApply && (
            <div className="rounded-b-md bg-primary-50 p-4">
              <button
                type="button"
                className="w-full rounded-md border border-accent-600 bg-accent-600 px-3 py-2 text-sm font-normal leading-6 text-white transition-colors duration-300 hover:bg-accent-800"
                onClick={() => {
                  if (onApply) {
                    onApply()
                  }
                  setOpened((o) => !o)
                }}
              >
                Apply Filter
              </button>
            </div>
          )}
        </Popover.Dropdown>
      </Popover>
      {error && <p className="text-xs text-red-500">{error}</p>}
    </div>
  )
}

export const FilterList: React.FunctionComponent<
  React.PropsWithChildren<
    Pick<
      MultiSelectFilterProps,
      'options' | 'value' | 'onChange' | 'customItem' | 'selectedFirst'
    > & {
      rowSize?: number
    }
  >
> = ({ options, value, onChange, customItem, rowSize, selectedFirst }) => {
  const ref = useRef<HTMLDivElement | null>(null)

  const flatten = useMemo(() => {
    let sortedOptions = [...options]

    if (selectedFirst) {
      sortedOptions = sortedOptions.sort((a, b) => {
        const aSelected = value.includes(a.value) ? -1 : 1
        const bSelected = value.includes(b.value) ? -1 : 1
        return aSelected - bSelected
      })
    }

    if (sortedOptions.every((o) => Boolean(o?.group))) {
      const grouped = groupBy(sortedOptions, (o) => o?.group ?? 'Others')
      const groups = Object.keys(grouped).sort()
      return groups.reduce((acc, group) => {
        const groupOptions = grouped[group]
        return [...acc, group, ...groupOptions]
      }, [] as (Option | string)[])
    }
    return sortedOptions
  }, [options, value, selectedFirst])

  // The virtualizer
  const rowVirtualizer = useVirtualizer({
    count: flatten.length,
    getScrollElement: () => ref.current,
    estimateSize: () => rowSize || 44,
    overscan: 13,
  })

  return (
    <div className="mt-1 max-h-64 overflow-y-auto" ref={ref}>
      <div
        style={{
          height: `${rowVirtualizer.getTotalSize()}px`,
          width: '100%',
          position: 'relative',
        }}
      >
        {rowVirtualizer.getVirtualItems().map((virtualRow) => {
          const option = flatten[virtualRow.index]
          const disabled = typeof option !== 'string' && option.count != null && option.count === 0

          const isSelected = typeof option !== 'string' && value.includes(option.value)
          const onClick = () => {
            // handle group label
            if (typeof option === 'string') return

            onChange(xor(value, [option.value]), isSelected ? 'remove' : 'add', option)
          }

          return (
            <div
              key={typeof option === 'string' ? option : option.value}
              style={{
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                height: `${virtualRow.size}px`,
                transform: `translateY(${virtualRow.start}px)`,
              }}
            >
              {typeof option === 'string' ? (
                // group label
                <p className="px-3 py-2 text-xs text-gray">{option}</p>
              ) : customItem ? (
                customItem({ isSelected, disabled, option, onClick })
              ) : (
                <MultiSelectFilterItem disabled={disabled}>
                  <MultiSelectFilterCheckBox
                    option={option}
                    disabled={disabled}
                    isSelected={isSelected}
                    onClick={onClick}
                  />
                  <MultiSelectFilterLabel option={option} disabled={disabled}>
                    <div className="truncate">{option?.label ?? option.value}</div>
                    {option.count && (
                      <p className="text-gray-500">{formatNumberCompact(option.count)}</p>
                    )}
                  </MultiSelectFilterLabel>
                </MultiSelectFilterItem>
              )}
            </div>
          )
        })}
      </div>
    </div>
  )
}

export const MultiSelectFilterItem = ({
  children,
  disabled,
}: {
  children: ReactNode
  disabled?: boolean
}) => {
  return (
    <div
      className={cn(
        'flex items-center space-x-3 rounded-md px-4 py-3 hover:bg-gray-50',
        disabled && 'pointer-events-none'
      )}
    >
      {children}
    </div>
  )
}

export const MultiSelectFilterCheckBox = ({
  option,
  isSelected,
  onClick,
  disabled,
}: {
  isSelected: boolean
  disabled?: boolean
  option: Option
  onClick: () => void
}) => {
  return (
    <Checkbox
      disabled={disabled}
      size="xs"
      id={`filterOption-${option.value}`}
      checked={isSelected}
      onChange={onClick}
    />
  )
}

export const MultiSelectFilterLabel = ({
  children,
  option,
  disabled,
}: {
  children: ReactNode
  disabled?: boolean
  option: Option
}) => {
  return (
    <label
      htmlFor={`filterOption-${option.value}`}
      className={cn(
        'flex w-full cursor-pointer items-center justify-between space-x-2 truncate text-sm',
        disabled && 'pointer-events-none'
      )}
    >
      {children}
    </label>
  )
}
