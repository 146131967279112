import clsx, { ClassValue } from 'clsx'
import { extendTailwindMerge, twMerge } from 'tailwind-merge'

const customTwMerge = extendTailwindMerge({
  extend: {
    theme: {
      scale: [{ text: ['xxs'] }],
    },
  },
})

export const cn = (...classes: ClassValue[]) => twMerge(clsx(...classes))
// export { classNames as cn }
