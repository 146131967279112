import * as React from 'react'

import { cn } from '@/util/classNames'

function Logo(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width={87.142} height={27.307} {...props}>
      <path
        data-name="Path 983"
        d="M18.018 10.245C18.018 4.265 15.546 0 9.089 0 2.472 0 0 4.584 0 10.683c0 4.142 1.315 10.642 10.524 10.642a23.256 23.256 0 006.139-.718v-4.7a23.092 23.092 0 01-5.74.638c-3.986 0-5.222-1.475-5.581-3.986h12.677zM9.01 4.784c2.551 0 3.667 1.2 3.827 3.986H5.223c.159-2.671 1.315-3.986 3.787-3.986zM35.679.399v9.567c0 3.189-1.435 4.584-4.544 4.584s-4.544-1.4-4.544-4.584V.399H21.41v9.767c0 5.461 2.472 9.169 8.73 9.169a7.388 7.388 0 005.541-2.193v5.383h-11.56v4.782H40.86V.399zm23.719 0l-4.506 15.267h-.638L49.75.399h-5.5l6.337 20.526h7.973L64.898.395zm25.153 15.546V.399H69.004v4.584h10.365v4.385c-1-1.475-2.551-2.392-5.541-2.392-4.863 0-7.136 3.508-7.136 7.175s2.272 7.174 7.135 7.174c2.99 0 4.544-.957 5.541-2.392v1.992h7.773v-4.98zm-8.93 1.08c-2.711 0-3.747-1.276-3.747-2.87s1.036-2.87 3.747-2.87 3.747 1.276 3.747 2.87-1.036 2.87-3.747 2.87z"
      />
    </svg>
  )
}

function LogoSanta({ className }: any) {
  return <img src="/logo-santa.webp" alt="webp" className={cn(className, '-mt-2 h-10 w-24')} />
}

const LogoIcon = React.memo(Logo)
export default LogoIcon
